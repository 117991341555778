import React from 'react';
import useAutocomplete from './useAutocomplete';

import s from './Autocomplete.module.scss';

const Autocomplete = ({
  id,
  label,
  placeholder,
}: {
  id: string;
  label: string;
  placeholder: string;
}) => {
  const {
    handleInputBlur,
    handleInputChange,
    handleInputReset,
    handleMouseDown,
    handleResultItemClick,
    inputValue,
    suggestions,
  } = useAutocomplete();

  return (
    <div className={s['Autocomplete']} onBlur={handleInputBlur} tabIndex={-1}>
      <div className={s['Input']}>
        <label htmlFor={id}>{label}</label>
        <input
          autoComplete="off"
          id={id}
          onChange={handleInputChange}
          placeholder={placeholder}
          type="text"
          value={inputValue}
        />
        {inputValue.length > 0 && (
          <span onClick={handleInputReset} onMouseDown={handleMouseDown}>
            +
          </span>
        )}
      </div>

      {suggestions.length > 0 && (
        <div className={s['Results']} onMouseDown={handleMouseDown}>
          <ul>
            {suggestions.map((item) => {
              return (
                <li
                  data-id={item.id}
                  key={item.id}
                  onClick={handleResultItemClick}
                >
                  {item.text}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
