import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import {
  FILTERS_REVERSE_MAP,
  makeQuery,
  parsingNotificationData,
  translate,
} from '../../utils';
import { EDataStore, ERouterPath, IReverseKeys, IState } from '../../types';
import { dataActions } from '../../store/actions/data.actions';

const useActiveFilter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataStore = useSelector((state: IState) => state.data);

  const editFilter = () => {
    navigate('/');
  };

  const saveNotification = () => {
    navigate({ pathname: ERouterPath.SaveNotification });
  };

  const handleRemoveItem = (event: MouseEvent<HTMLSpanElement>) => {
    const label = event.currentTarget.getAttribute('data-label');
    if (label === null || label === undefined) return;
    const filter = FILTERS_REVERSE_MAP[label as IReverseKeys];
    dispatch({ type: EDataStore.SET_LOADING, loading: true });
    Promise.all([
      dispatch(dataActions.deleteCertainFilter(filter) as unknown as AnyAction),
    ]).then(() => {
      const query = `?${makeQuery(dataStore.selectedFilters)}`;
      new Promise((resolve) =>
        resolve(
          dispatch(
            dataActions.searchByFilterQuery(query) as unknown as AnyAction
          )
        )
      ).then(() => {
        navigate({
          pathname: ERouterPath.Results,
          search: query,
        });
        dispatch({ type: EDataStore.SET_LOADING, loading: false });
      });
    });
  };

  return {
    activeFilters: parsingNotificationData(dataStore.selectedFilters),
    editFilter,
    handleRemoveItem,
    t,
    translate: translate,
    saveNotification,
  };
};

export default useActiveFilter;
