import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import s from './SinglePost.module.scss';

const SinglePostFeatures = ({ data }: { data: string }) => {
  const { t } = useTranslation();
  const [arrData, setArrData] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      const toArray = data.split(';');
      toArray.splice(-1, 1);
      setArrData(toArray);
    }
  }, [data]);

  return (
    <div className={s['TechData']}>
      <h1>{t('single.post.features')}</h1>
      {arrData.map((item) => {
        return (
          <div className={s['Text']} key={item}>
            <p>{item}</p>
          </div>
        );
      })}
    </div>
  );
};

export default SinglePostFeatures;
