import React from 'react';
import ReactLoading from 'react-loading';
import useStepper from './useStepper';
import Button from '../UI/Button';
import BlogCard from '../BlogCard';
import { Step1, Step2, Step3 } from './';

import s from './Stepper.module.scss';

const Stepper = () => {
  const { handleSearch, loading, saveNotification, t, total } = useStepper();

  return (
    <div className={s['Wrapper']}>
      <div className={s['Tab']}>
        <span>{t('stepper.step1')}</span>
      </div>
      <div className={s['Content']}>
        <Step1 />
      </div>

      <div className={s['Tab']}>
        <span>{t('stepper.step2')}</span>
      </div>
      <div className={s['Content']}>
        <Step2 />
      </div>

      <div className={s['Tab']}>
        <span>{t('stepper.step3')}</span>
      </div>
      <div className={s['Content']}>
        <Step3 />

        <div className={s['Footer']}>
          <div className={s['BtnSearch']}>
            <Button
              btnClass="btnPrimary"
              label={t('btn.search')}
              onClick={handleSearch}
            />
            <div>
              {loading ? (
                <ReactLoading
                  type={'spokes'}
                  color={'#FFA217'}
                  className={s['Loader']}
                />
              ) : (
                <small>{total}</small>
              )}
            </div>
          </div>
          <div className={s['BtnSkip']}>
            <Button
              btnClass="btnPrimary"
              label={t('stepper.setNotification')}
              onClick={saveNotification}
            />
            <Button
              btnClass="btnGrey"
              label={t('btn.submit')}
              onClick={handleSearch}
            />
          </div>
        </div>
      </div>

      <div className="ads">
        <BlogCard />
      </div>
    </div>
  );
};

export default Stepper;
