import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { dataActions } from '../../../store/actions/data.actions';
import { EFilter, ERange, ISelect, IState } from '../../../types';
import { formatMileage, formatPrice } from '../../../utils';
import {
  CUBIC_CAPACITY,
  HORSE_POWER,
  MILEAGE,
  PRICE,
} from '../../../constants';

const useInputRange = (filter: EFilter) => {
  const { t } = useTranslation();
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const dataStore = useSelector((state: IState) => state.data);

  const handleSelectorChange = (data: ISelect, type: ERange) => {
    const filters = dataStore.selectedFilters;

    if (data === null) {
      if (
        Number(filters[filter + ERange.from]) === 0 ||
        Number(filters[filter + ERange.to]) === 100000000
      ) {
        delete filters[filter + ERange.from];
        delete filters[filter + ERange.to];
        return dispatch(
          dataActions.selectedFilters(filters) as unknown as AnyAction
        );
      } else {
        if (type === ERange.from) {
          return dispatch(
            dataActions.selectedFilters({
              [filter + [type]]: 0,
            }) as unknown as AnyAction
          );
        }
        if (type === ERange.to) {
          return dispatch(
            dataActions.selectedFilters({
              [filter + [type]]: 100000000,
            }) as unknown as AnyAction
          );
        }
      }
    }

    if (type === ERange.from) {
      dispatch(
        dataActions.selectedFilters({
          [filter + ERange.from]: data.value,
          [filter + ERange.to]: filters[filter + ERange.to]
            ? filters[filter + ERange.to]
            : 100000000,
        }) as unknown as AnyAction
      );
    } else {
      dispatch(
        dataActions.selectedFilters({
          [filter + ERange.from]: filters[filter + ERange.from]
            ? filters[filter + ERange.from]
            : 0,
          [filter + ERange.to]: data.value,
        }) as unknown as AnyAction
      );
    }
  };

  const getOptions = (type?: ERange) => {
    const results: ISelect[] = [];
    const options: ISelect[] = [];

    switch (filter) {
      case EFilter.price:
        PRICE.map((item) =>
          options.push(getSelectorOption(item * 100, filter))
        );
        break;
      case EFilter.mileage:
        MILEAGE.map((item) =>
          options.push(getSelectorOption(item * 100, filter))
        );
        break;
      case EFilter.firstRegistrationYear:
        for (let i = new Date().getFullYear(); i > 1900; i--) {
          options.push(getSelectorOption(i, filter));
        }
        break;
      case EFilter.cubicCapacity:
        CUBIC_CAPACITY.map((item) =>
          options.push(getSelectorOption(item * 100, filter))
        );
        break;
      case EFilter.horsePower:
        HORSE_POWER.map((item) =>
          options.push(getSelectorOption(item, filter))
        );
        break;
      default:
        throw new Error('This filter does not exist!');
    }

    options?.map((option) => {
      if (option.value === undefined) return results.push(option);
      const filters = dataStore.selectedFilters;
      if (filters[filter + ERange.from] !== undefined && type === ERange.to) {
        if (Number(option.value) >= Number(filters[filter + ERange.from])) {
          results.push(option);
        }
      } else if (
        filters[filter + ERange.to] !== undefined &&
        type === ERange.from
      ) {
        if (Number(option.value) <= Number(filters[filter + ERange.to])) {
          results.push(option);
        }
      } else {
        results.push(option);
      }
    });
    return results;
  };

  const getSelectorOption = (data: number, filter: EFilter) => {
    let label: string;
    if (filter === EFilter.price) {
      label = formatPrice(Number(data));
    } else if (filter === EFilter.mileage) {
      label = formatMileage(Number(data));
    } else {
      label = String(data);
    }

    return {
      label: label,
      value: Number(data),
    };
  };

  const getSelectedValue = (type: ERange) => {
    const selectedData: ISelect[] = [];
    if (
      type === ERange.from &&
      Number(dataStore.selectedFilters[filter + ERange.from]) === 0
    ) {
      return selectedData;
    }
    if (
      type === ERange.to &&
      Number(dataStore.selectedFilters[filter + ERange.to]) === 100000000
    ) {
      return selectedData;
    }
    dataStore.selectedFilters[filter + [type]] !== undefined &&
      selectedData.push(
        getSelectorOption(dataStore.selectedFilters[filter + [type]], filter)
      );
    return selectedData;
  };

  return {
    filtersFrom: dataStore.selectedFilters[filter + 'From'],
    filtersTo: dataStore.selectedFilters[filter + 'To'],
    getOptions,
    getSelectedValue,
    handleSelectorChange,
    t,
  };
};

export default useInputRange;
