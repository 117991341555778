import React from 'react';
import { IButton } from '../../../types';

import s from './Button.module.scss';

const Button = ({
  label,
  btnClass,
  fullWidth,
  iconRight,
  iconLeft,
  disabled,
  onClick,
  values,
  onIconRightClick,
}: IButton) => {
  return (
    <button
      className={`${btnClass ? s[btnClass] : ''} ${
        fullWidth ? s['btnWide'] : ''
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {iconLeft && (
        <div className={s['iconLeft']}>
          <img alt={''} src={iconLeft} />
        </div>
      )}
      {values && <span className={s['values']}>{values}</span>}
      {label && <span className={s['text']}>{label}</span>}
      {iconRight && (
        <div className={s['iconRight']} onClick={onIconRightClick}>
          {iconRight}
        </div>
      )}
    </button>
  );
};

export default Button;
