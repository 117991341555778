import React from 'react';
import { useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import { urlWeb } from '../../environment';
import { authActions } from '../../store/actions/auth.actions';

import s from './Logoutbar.module.scss';
const LogoutBar = () => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const logout = async () => {
    dispatch(authActions.logout() as unknown as AnyAction);
    await keycloak.logout({ redirectUri: urlWeb }).then();
  };

  return (
    <div className={s['LogoutWrapper']}>
      {keycloak.authenticated && (
        <div className={s['Logout']} onClick={logout}>
          <i className="fa fa-sign-out" aria-hidden="true"></i>
          <p>{t('logout')}</p>
        </div>
      )}
    </div>
  );
};

export default LogoutBar;
