import { Dispatch, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { dataActions } from '../../../store/actions/data.actions';
import { makeQuery } from '../../../utils';
import { EDataStore, ERouterPath, ISelect, IState } from '../../../types';

const useSortBy = (pathname: ERouterPath) => {
  const { t } = useTranslation();
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const navigate = useNavigate();
  const dataStore = useSelector((state: IState) => state.data);
  const [searchParams] = useSearchParams();

  const SORT: ISelect[] = [
    { label: t('priceLow'), value: 'price,asc' },
    {
      label: t('priceHigh'),
      value: 'price,desc',
    },
    { label: t('ageOld'), value: 'firstRegistrationYear,asc' },
    { label: t('ageNew'), value: 'firstRegistrationYear,desc' },
    { label: t('mileageLow'), value: 'mileage,asc' },
    { label: t('mileageHigh'), value: 'mileage,desc' },
    { label: t('brandAZ'), value: 'brand,asc' },
    { label: t('brandZA'), value: 'brand,desc' },
    {
      label: t('distanceToCar'),
      value: 'distance,asc',
      isDisabled:
        !dataStore.selectedFilters.location ||
        !dataStore.selectedFilters.radius,
    },
    { label: t('horsePowerLow'), value: 'horsePower,asc' },
    { label: t('horsePowerHigh'), value: 'horsePower,desc' },
  ];

  useEffect(() => {
    const storeSort = dataStore.pagination.sort;
    const paramsSort = searchParams.get('sort');
    if (storeSort === undefined && paramsSort !== null) {
      dispatch(dataActions.setSortBy(paramsSort) as unknown as AnyAction);
    }
  }, []);

  const getSelectedSortBy = () => {
    return SORT.find((item) => item.value === dataStore.pagination?.sort);
  };

  const handleSelectSortBy = async (selected: ISelect) => {
    const query = makeQuery({
      ...dataStore.selectedFilters,
      ...dataStore.pagination,
      page: 1,
      sort: selected.value,
    });
    dispatch({ type: EDataStore.SET_LOADING, loading: true });
    Promise.all([
      dispatch(
        dataActions.setSortBy(String(selected.value)) as unknown as AnyAction
      ),
      dispatch(
        pathname === ERouterPath.Results
          ? (dataActions.searchByFilterQuery(
              `?${query}`
            ) as unknown as AnyAction)
          : (dataActions.getFavourites(`?${query}`) as unknown as AnyAction)
      ),
    ]).then(() => {
      navigate({ pathname: pathname, search: `?${query}` });
      dispatch({ type: EDataStore.SET_LOADING, loading: false });
    });
  };

  return { getSelectedSortBy, handleSelectSortBy, SORT, t };
};

export default useSortBy;
