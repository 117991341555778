import { Dispatch } from 'react';
import i18n from '../i18n';
import moment from 'moment';
import {
  EFilter,
  EPagination,
  IFilters,
  IReverse,
  IReverseKeys,
  ISelect,
} from '../types';
import {
  CAR_BODY_TYPE_OBJ,
  COLOR_TYPE_OBJ,
  DRIVE_TYPE_OBJ,
  FUEL_OBJ,
  GEARBOX_OBJ,
} from './constants';

export const formatPrice = (value: number) => {
  if (value === undefined || value === null) {
    return 'N/A';
  }
  return value.toLocaleString(i18n.t('localeString') || 'en-CH', {
    style: 'currency',
    currency: i18n.t('currency') || 'CHF',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
};

export const formatMileage = (value: number) => {
  if (value === undefined || value === null) {
    return 'N/A';
  }
  return `${value.toLocaleString(i18n.t('localeString') || 'en-CH')} km`;
};

export const getId = (path: string, state?: Dispatch<string>) => {
  const lastItem = path.substring(path.lastIndexOf('/') + 1);
  if (state) {
    state(lastItem);
  }
  return lastItem;
};

export const isObjEmpty = (obj: object) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

export const makeQuery = (data: IFilters) => {
  const array = [EPagination.totalElements, EPagination.totalPages];
  const result: string[] = [];
  Object.keys(data).map((key: string) => {
    if (!array.includes(key as EPagination)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const item = data[key];
      if (item === 'brand,asc' || item === 'brand,desc') {
        result.push(key + '=' + item);
        result.push(key + '=' + `model,${item.split(',')[1]}`);
      } else {
        result.push(key + '=' + item);
      }
    }
  });
  return result.join('&');
};

export const mapDataToString = (arr: ISelect[]) => {
  let result = '';
  arr.map((item: ISelect) => {
    result += item.value + ',';
  });
  return result;
};

export const parsingNotificationData = (filters: IFilters) => {
  const result: IReverse = {};
  if (filters['cubicCapacityFrom'] || filters['cubicCapacityTo'])
    result.cubicCapacity = [];
  if (filters['entryDateFrom'] || filters['entryDateFrom'])
    result.entryDate = [];
  if (filters['horsePowerFrom'] || filters['horsePowerFrom'])
    result.horsePower = [];
  if (
    filters['firstRegistrationYearFrom'] ||
    filters['firstRegistrationYearFrom']
  )
    result.firstRegistrationYear = [];
  Object.entries(filters).map(([key, value]) => {
    switch (true) {
      case key === 'cubicCapacityFrom' || key === 'cubicCapacityTo':
        result.cubicCapacity?.push(value);
        break;
      case key === 'entryDateFrom' || key === 'entryDateTo':
        result.entryDate?.push(value);
        break;
      case key === 'horsePowerFrom' || key === 'horsePowerTo':
        result.horsePower?.push(value);
        break;
      case key === 'firstRegistrationYearFrom' ||
        key === 'firstRegistrationYearTo':
        result.firstRegistrationYear?.push(value);
        break;
      case key === 'priceFrom' || key === 'priceTo':
        result.price?.push(value);
        break;
      case key === 'mileageFrom' || key === 'mileageTo':
        result.mileage?.push(value);
        break;
      case key === 'driveType':
        result[key as IReverseKeys] = DRIVE_TYPE_OBJ[value as never];
        break;
      case key === 'gearbox':
        result[key as IReverseKeys] = GEARBOX_OBJ[value as never];
        break;
      case key === 'bodyColor':
        result[key as IReverseKeys] = convertNotificationItem(
          value,
          COLOR_TYPE_OBJ
        );
        break;
      case key === 'bodyType':
        result[key as IReverseKeys] = convertNotificationItem(
          value,
          CAR_BODY_TYPE_OBJ
        );
        break;
      case key === 'fuel':
        result[key as IReverseKeys] = convertNotificationItem(value, FUEL_OBJ);
        break;
      case key === 'size' ||
        key === 'page' ||
        key === 'latitude' ||
        key === 'longitude' ||
        key === 'sort':
        break;
      default:
        result[key as IReverseKeys] = value;
    }
  });
  return result;
};

export const reverseNotificationData = (value: number[], key: string) => {
  if (key === EFilter.bodyType) return reverseArray(value, CAR_BODY_TYPE_OBJ);
  if (key === EFilter.bodyColor) return reverseArray(value, COLOR_TYPE_OBJ);
  if (key === EFilter.fuel) return reverseArray(value, FUEL_OBJ);
};

const convertNotificationItem = (
  value: string,
  object: { [key: number]: string }
) => {
  const result: string[] = [];

  if (Array.isArray(value)) {
    value.map((item: string) => {
      result.push(i18n.t(object[item as unknown as number]));
    });
  } else {
    value.split(',').map((item: string) => {
      result.push(i18n.t(object[item as unknown as number]));
    });
  }

  return result;
};

export const printNotificationItem = (item: string | string[], key: string) => {
  if (Array.isArray(item)) {
    if (
      key === EFilter.bodyType ||
      key === EFilter.bodyColor ||
      key === EFilter.brand ||
      key === EFilter.model ||
      key === EFilter.fuel
    ) {
      return item.join(', ');
    } else {
      return item.join(' - ');
    }
  } else {
    if (key === EFilter.brand || key === EFilter.model) {
      return item?.split(',').join(', ');
    } else {
      return item;
    }
  }
};

export const numberWithCommas = (n: number | string) => {
  if (n) {
    const parts = n.toString().split('.');
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      (parts[1] ? '.' + parts[1] : '')
    );
  }
};

const reverseArray = (data: number[], obj: { [key: number]: string }) => {
  const result: string[] = [];
  data.map((item: number) => {
    result.push(obj[item]);
  });
  return result;
};

export const queryToObj = (data: string) => {
  const params: { [key: string]: string | string[] } = Object.fromEntries(
    new URLSearchParams(data)
  );
  Object.entries(params).map(([key, value]) => {
    if (typeof value === 'string') {
      if (
        key === 'brand' ||
        key === 'model' ||
        key === 'fuel' ||
        key === 'bodyType' ||
        key === 'bodyColor'
      ) {
        params[key] = value.split(',');
      }
    }
  });
  return params;
};

export const translate = (value: string[], key: string) => {
  const values: string[] = [];
  if (key === EFilter.entryDate) {
    const date = moment(value[0]);
    switch (true) {
      case date.isAfter(moment().subtract(1, 'days')):
        values.push(i18n.t('entryDate2'));
        break;
      case date.isAfter(moment().subtract(7, 'days')):
        values.push(i18n.t('entryDate3'));
        break;
      case date.isAfter(moment().subtract(1, 'month')):
        values.push(i18n.t('entryDate4'));
        break;
      case date.isAfter(moment().subtract(3, 'month')):
        values.push(i18n.t('entryDate5'));
        break;
      default:
        values.push(i18n.t('entryDate1'));
    }
    return values;
  } else {
    value.map((item: string) => {
      if (Number(item) === 100000000) {
        values.push(i18n.t('unlimited'));
      } else {
        values.push(i18n.t(item));
      }
    });

    if (
      key === EFilter.bodyType ||
      key === EFilter.bodyColor ||
      key === EFilter.brand ||
      key === EFilter.model ||
      key === EFilter.fuel
    ) {
      return values.join(', ');
    } else {
      return values.join(' - ');
    }
  }
};
