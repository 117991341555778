import React from 'react';
import useInputRange from './useInputRange';
import Selector from '../Selector';
import { ERange, IInputRange, ISelect } from '../../../types';

import s from './InputRange.module.scss';

const InputRange = ({ filter, label }: IInputRange) => {
  const { getSelectedValue, getOptions, handleSelectorChange, t } =
    useInputRange(filter);

  return (
    <div className={s['InputRange']}>
      <label>{label}</label>

      <div className={s['Row']}>
        <div className={s['Col']}>
          <Selector
            selectClassname="selectFilter"
            options={getOptions(ERange.from)}
            handleChangeSelect={(e: ISelect) =>
              handleSelectorChange(e, ERange.from)
            }
            placeholder={t('label.from')}
            selectedOption={getSelectedValue(ERange.from)}
            isClearable={true}
          />
        </div>
        <div className={s['Col']}>
          <Selector
            selectClassname="selectFilter"
            options={getOptions(ERange.to)}
            handleChangeSelect={(e: ISelect) =>
              handleSelectorChange(e, ERange.to)
            }
            placeholder={t('label.to')}
            selectedOption={getSelectedValue(ERange.to)}
            isClearable={true}
          />
        </div>
      </div>
    </div>
  );
};

export default InputRange;
