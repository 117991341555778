import { Dispatch, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { dataActions } from '../../store/actions/data.actions';
import { authActions } from '../../store/actions/auth.actions';
import { urlWeb } from '../../environment';
import { ERouterPath } from '../../types';

const useNavbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const { keycloak } = useKeycloak();
  const [show, setShow] = useState(false);

  const logout = async () => {
    dispatch(authActions.logout() as unknown as AnyAction);
    await keycloak.logout({ redirectUri: urlWeb });
  };

  const goToHome = () => {
    dispatch(dataActions.selectedFilters({}) as unknown as AnyAction);
    navigate({ pathname: ERouterPath.Home, search: '' });
  };

  const hideDropdown = () => {
    setShow(false);
  };

  const toggleNav = () => {
    setShow(!show);
  };

  return {
    authenticated: keycloak.authenticated,
    logout,
    goToHome,
    hideDropdown,
    show,
    toggleNav,
    t,
  };
};

export default useNavbar;
