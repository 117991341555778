const env = process.env.REACT_APP_ENVIROMENT;
let url: string,
  urlWeb: string,
  keycloakUrl: string,
  keycloakRealm: string,
  keycloakClientId: string;

if (env === 'prod') {
  url = 'https://all.cars';
  urlWeb = 'https://all.cars/';
  keycloakUrl = 'https://all.cars/auth/';
  keycloakRealm = 'AllCars';
  keycloakClientId = 'all-cars-frontend';
} else {
  url = 'https://all.cars';
  urlWeb = 'https://all.cars/';
  keycloakUrl = 'https://all.cars/auth/';
  keycloakRealm = 'AllCars';
  keycloakClientId = 'all-cars-frontend';
}

export { url, urlWeb, keycloakUrl, keycloakRealm, keycloakClientId };
