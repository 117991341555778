import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { dataActions } from '../../store/actions/data.actions';
import { ERouterPath, IFilters } from '../../types';
import { useNavigate } from 'react-router-dom';
import {
  makeQuery,
  parsingNotificationData,
  printNotificationItem,
  translate,
} from '../../utils';

const useNotificationCard = (data: IFilters, id: string) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openNotification = () => {
    const res = Object.assign({}, data);
    delete res._links;
    const query = makeQuery(res);
    navigate({ pathname: ERouterPath.Results, search: `?${query}` });
  };

  const deleteNotification = async () => {
    new Promise((resolve) =>
      resolve(
        dispatch(dataActions.deleteNotification(id) as unknown as AnyAction)
      )
    );
  };

  return {
    deleteNotification,
    notification: parsingNotificationData(data),
    openNotification,
    printNotificationItem: printNotificationItem,
    t,
    translate: translate,
  };
};

export default useNotificationCard;
