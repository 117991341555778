import React from 'react';
import { NavLink } from 'react-router-dom';
import useNavbar from './useNavbar';
import LanguageSelector from '../Language';

import {
  Logo,
  LogoBlack,
  BellMobIcon,
  BlogMobIcon,
  CloseMobIcon,
  DocumentMobIcon,
  HamburgerMobIcon,
  InfoMobIcon,
  StarMobIcon,
  UserMobIcon,
} from '../../assets/images';

import s from './Navbar.module.scss';

const NavbarMob = () => {
  const { authenticated, logout, goToHome, hideDropdown, show, toggleNav, t } =
    useNavbar();

  return (
    <div className={s['NavMob']}>
      {!show && (
        <div className={s['NavHeader']}>
          <img alt={''} className={s['Logo']} onClick={goToHome} src={Logo} />
          <img
            alt={''}
            src={HamburgerMobIcon}
            className={s['NavbarBrand']}
            onClick={toggleNav}
          />
        </div>
      )}
      {show && (
        <nav className={s['NavDropdown']}>
          <div>
            <img alt={''} src={LogoBlack} onClick={hideDropdown} />
            <img
              alt={''}
              className={s['Close']}
              src={CloseMobIcon}
              onClick={toggleNav}
            />
          </div>
          <ul>
            <li onClick={hideDropdown}>
              <div className={s['NavIcon']}>
                <img alt={''} src={StarMobIcon} />
              </div>
              <NavLink to="/favourites">{t('navbar.link.favourites')}</NavLink>
            </li>
            <li onClick={hideDropdown}>
              <div className={s['NavIcon']}>
                <img alt={''} src={UserMobIcon} />
              </div>
              <NavLink to="/profile">{t('navbar.link.profile')}</NavLink>
            </li>
            <li onClick={hideDropdown}>
              <div className={s['NavIcon']}>
                <img alt={''} src={BellMobIcon} />
              </div>
              <NavLink to="notifications">
                {t('navbar.link.notifications')}
              </NavLink>
            </li>
            <li onClick={hideDropdown}>
              <div className={s['NavIcon']}>
                <img alt={''} src={InfoMobIcon} />
              </div>
              <NavLink to="/privacy-policy">{t('navbar.link.privacy')}</NavLink>
            </li>
            <li onClick={hideDropdown}>
              <div className={s['NavIcon']}>
                <img alt={''} src={DocumentMobIcon} />
              </div>
              <NavLink to="/terms-and-conditions">
                {t('navbar.link.legal')}
              </NavLink>
            </li>
            <li onClick={hideDropdown}>
              <div className={s['NavIcon']}>
                <img alt={''} src={BlogMobIcon} />
              </div>
              <NavLink to="/blog">{t('navbar.link.blog')}</NavLink>
            </li>
            <li className={s['Language']}>
              <LanguageSelector />
            </li>
            {authenticated && (
              <li className={s['Logout']} onClick={logout}>
                <i className="fa fa-sign-out" aria-hidden="true"></i>{' '}
                <span>{t('logout')}</span>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default NavbarMob;
