import React from 'react';
import useNotificationCard from './useNotificationCard';
import Button from '../UI/Button';
import { FILTERS_LABEL_MAP } from '../../utils';
import { EReverse, IFilters, IReverseKeys } from '../../types';

import s from './NotificationCard.module.scss';

const NotificationCard = ({ data, id }: { data: IFilters; id: string }) => {
  const {
    deleteNotification,
    notification,
    openNotification,
    printNotificationItem,
    t,
  } = useNotificationCard(data, id);

  return (
    <div className={s['Wrapper']}>
      <div className={s['Container']}>
        {<h4>{data?.name}</h4>}
        {Object.keys(notification).map((key: string) => (
          <div className={s['Text']} key={key}>
            {FILTERS_LABEL_MAP[key as EReverse] && (
              <p>{t(FILTERS_LABEL_MAP[key as EReverse])}</p>
            )}
            {FILTERS_LABEL_MAP[key as EReverse] && (
              <p>
                {printNotificationItem(
                  notification[key as IReverseKeys] as string,
                  key
                )}
              </p>
            )}
          </div>
        ))}
      </div>
      <div className={s['Options']}>
        <Button
          label={t('btn.run')}
          onClick={openNotification}
          btnClass="btnPrimary"
        />
        <Button
          label={t('btn.delete')}
          onClick={deleteNotification}
          btnClass="btnSecondary"
        />
      </div>
    </div>
  );
};

export default NotificationCard;
