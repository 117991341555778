export enum EReverse {
  cubicCapacity = 'cubicCapacity',
  entryDate = 'entryDate',
  firstRegistrationYear = 'firstRegistrationYear',
  horsePower = 'horsePower',
  mileage = 'mileage',
  price = 'price',
  bodyColor = 'bodyColor',
  bodyType = 'bodyType',
  brand = 'brand',
  country = 'country',
  driveType = 'driveType',
  fuel = 'fuel',
  gearbox = 'gearbox',
  model = 'model',
  seats = 'seats',
  name = 'name',
}

export enum ELanguage {
  De = 'de',
  En = 'en',
  Fr = 'fr',
  It = 'it',
}

export enum ECardType {
  cardPrimary = 'CardPrimary',
  cardSecondary = 'CardSecondary',
}

// export enum EFiltersReverseMap {
//   cubicCapacity = ['cubicCapacityFrom', 'cubicCapacityTo'],
//   entryDate = ['entryDateFrom', 'entryDateTo'],
//   firstRegYear = ['firstRegistrationYearFrom', 'firstRegistrationYearTo'],
//   horsePower = ['horsePowerFrom', 'horsePowerTo'],
//   mileage = ['mileageFrom', 'mileageTo'],
//   price = ['priceFrom', 'priceTo'],
//   bodyColor = 'bodyColor',
//   bodyType = 'bodyType',
//   brand = 'brand',
//   city = 'city',
//   country = 'country',
//   driveType = 'driveType',
//   fuel = 'fuel',
//   gearbox = 'gearbox',
//   model = 'model',
//   seats = 'seats',
// }
