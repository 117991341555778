import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useTranslation } from 'react-i18next';
import { dataActions } from '../../../store/actions/data.actions';
import { EFilter, ISelect, IState } from '../../../types';
import { ENTRY_DATE_OBJ } from '../../../utils';
import { STEP_3_TYPES } from '../../../constants';

const useStep3 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataStore = useSelector((state: IState) => state.data);

  const [selectedColors, setSelectedColors] = useState(
    dataStore.selectedFilters.bodyColor
      ? dataStore.selectedFilters.bodyColor.map((item: number) => +item)
      : []
  );

  const handleSelectChange = async (selected: ISelect, type: string) => {
    if (selected) {
      storeFilter({
        [type]: type === EFilter.radius ? selected.value : selected.id,
      });
    } else {
      const filter = dataStore.selectedFilters;
      delete filter[type];
      storeFilter(filter);
    }
  };

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === EFilter.entryDate) {
      storeFilter(ENTRY_DATE_OBJ[value as '1' | '2' | '3' | '4' | '5']);
    } else {
      storeFilter({ [name]: value });
    }
  };

  const handleColorChange = async (id: string) => {
    let newColors: string[] = [...selectedColors];
    if (newColors.includes(id)) {
      newColors = newColors.filter((item) => item !== id);
      setSelectedColors(newColors);
    } else {
      newColors.push(id);
      setSelectedColors(newColors);
    }
    if (newColors.length > 0) {
      storeFilter({ bodyColor: newColors });
    } else {
      const filter = dataStore.selectedFilters;
      delete filter.bodyColor;
      storeFilter(filter);
      setSelectedColors([]);
    }
  };

  const storeFilter = (data: {
    [key: string]: number | number[] | string | string[] | undefined;
  }) => {
    dispatch(dataActions.selectedFilters(data) as unknown as AnyAction);
  };

  const getSelectedValue = (options: ISelect[], type: string) => {
    const selectedData: ISelect[] = [];
    const filters = dataStore.selectedFilters;
    const element = options.find((option) =>
      type === EFilter.radius
        ? option.value === filters[type]
        : option.id === filters[type]
    );
    element !== undefined && selectedData.push(element);
    return selectedData;
  };

  const resetFilter = async () => {
    const filters = dataStore.selectedFilters;
    STEP_3_TYPES.forEach((item) => {
      delete filters[item];
    });
    storeFilter(filters);
    setSelectedColors([]);
  };

  return {
    filters: dataStore.selectedFilters,
    getSelectedValue,
    handleRadioChange,
    handleColorChange,
    handleSelectChange,
    resetFilter,
    selectedColors,
    t,
  };
};

export default useStep3;
