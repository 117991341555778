import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useNavbar from './useNavbar';
import LogoutBar from '../LogoutBar/LogoutBar';
import LanguageSelector from '../Language';

import {
  LogoBlack,
  BellWebIcon,
  BlogWebIcon,
  DocumentWebIcon,
  InfoWebIcon,
  StarWebIcon,
  UserWebIcon,
} from '../../assets/images';

import s from './Navbar.module.scss';
import { ERouterPath } from '../../types';

const Item = ({
  icon,
  id,
  text,
  to,
}: {
  icon: ReactElement;
  id: string;
  text: string;
  to: ERouterPath;
}) => {
  return (
    <li className={s['NavItem']}>
      <Link className={s['NavLink']} data-tooltip-id={id} to={to}>
        {icon}
      </Link>
      <ReactTooltip id={id} place="bottom" content={text} />
    </li>
  );
};

const NavbarWeb = () => {
  const { goToHome, t } = useNavbar();

  return (
    <nav className={s['NavWeb']}>
      <div className={s['NavLeft']}>
        <img alt="" className={s['Logo']} onClick={goToHome} src={LogoBlack} />
      </div>
      <div className={s['NavRight']}>
        <div className={s['NavSearch']}>
          <LogoutBar />
          <div className={s['VersionDisplay']}>
            <h3>BETA</h3>
          </div>
        </div>
        <div className={s['NavRightItems']}>
          <ul className={s['NavAuth']}>
            <Item
              icon={<StarWebIcon />}
              id="icoFavourites"
              text={t('navbar.link.favourites')}
              to={ERouterPath.Favourites}
            />
            <Item
              icon={<UserWebIcon />}
              id="icoProfile"
              text={t('navbar.link.profile')}
              to={ERouterPath.Profile}
            />
            <Item
              icon={<BellWebIcon />}
              id="icoNotifications"
              text={t('navbar.link.notifications')}
              to={ERouterPath.Notifications}
            />
            <Item
              icon={<InfoWebIcon />}
              id="icoPrivacyPolicy"
              text={t('navbar.link.privacy')}
              to={ERouterPath.PrivacyPolicy}
            />
            <Item
              icon={<DocumentWebIcon />}
              id="icoTermsAndConditions"
              text={t('navbar.link.legal')}
              to={ERouterPath.TermsAndConditions}
            />
            <Item
              icon={<BlogWebIcon />}
              id="icoBlog"
              text={t('navbar.link.blog')}
              to={ERouterPath.Blog}
            />
          </ul>
          <div className={s['NavLng']}>
            <LanguageSelector />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarWeb;
