export enum EMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export enum EAuthStore {
  LOGIN_REQUEST = 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE = 'USERS_LOGIN_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',

  GET_ALL_REQUEST = 'USERS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS = 'USERS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE = 'USERS_GET_ALL_FAILURE',

  UPDATE_REQUEST = 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS = 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE = 'USERS_UPDATE_FAILURE',

  DELETE_REQUEST = 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS = 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE = 'USERS_DELETE_FAILURE',
}

export enum EDataStore {
  DATA_REQUEST = 'DATA_REQUEST',
  DATA_SUCCESS = 'DATA_SUCCESS',
  DATA_FAILURE = 'DATA_FAILURE',

  FAVOURITES_REQUEST = 'LIST_FAVOURITES_REQUEST',
  FAVOURITES_SUCCESS = 'LIST_FAVOURITES_SUCCESS',
  FAVOURITES_FAILURE = 'LIST_FAVOURITES_FAILURE',

  FAVORITE_IDS_REQUEST = 'FAVORITE_IDS_REQUEST',
  FAVORITE_IDS_SUCCESS = 'FAVORITE_IDS_SUCCESS',
  FAVORITE_IDS_FAILURE = 'FAVORITE_IDS_FAILURE',

  SEARCH_REQUEST = 'LIST_SEARCH_REQUEST',
  SEARCH_SUCCESS = 'LIST_SEARCH_SUCCESS',
  SEARCH_FAILURE = 'LIST_SEARCH_FAILURE',

  MARK_REQUEST = 'MARK_REQUEST',
  MARK_SUCCESS = 'MARK_SUCCESS',
  MARK_FAILURE = 'MARK_FAILURE',

  UNMARK_REQUEST = 'UNMARK_REQUEST',
  UNMARK_SUCCESS = 'UNMARK_SUCCESS',
  UNMARK_FAILURE = 'UNMARK_FAILURE',

  SORT_REQUEST = 'SORT_REQUEST',
  SORT_SUCCESS = 'SORT_SUCCESS',
  SORT_FAILURE = 'SORT_FAILURE',

  SINGLE_POST_REQUEST = 'SINGLE_POST_REQUEST',
  SINGLE_POST_SUCCESS = 'SINGLE_POST_SUCCESS',
  SINGLE_POST_FAILURE = 'SINGLE_POST_FAILURE',

  BRAND_REQUEST = 'BRAND_REQUEST',
  BRAND_SUCCESS = 'BRAND_SUCCESS',
  BRAND_FAILURE = 'BRAND_FAILURE',

  MODEL_REQUEST = 'MODEL_REQUEST',
  MODEL_SUCCESS = 'MODEL_SUCCESS',
  MODEL_FAILURE = 'MODEL_FAILURE',

  SELECTED_FILTERS_SET = 'SELECTED_FILTERS_SET',
  SELECTED_FILTERS_DELETE = 'SELECTED_FILTERS_DELETE',

  GET_DATA_QUERY_REQUEST = 'GET_DATA_QUERY_REQUEST',
  GET_DATA_QUERY_SUCCESS = 'GET_DATA_QUERY_SUCCESS',
  GET_DATA_QUERY_FAILURE = 'GET_DATA_QUERY_FAILURE',

  DATA_COUNT_QUERY_REQUEST = 'DATA_COUNT_QUERY_REQUEST',
  DATA_COUNT_QUERY_SUCCESS = 'DATA_COUNT_QUERY_SUCCESS',
  DATA_COUNT_QUERY_FAILURE = 'DATA_COUNT_QUERY_FAILURE',

  NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST',
  NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS',
  NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE',

  ADD_NOTIFICATION_REQUEST = 'ADD_NOTIFICATION_REQUEST',
  ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS',
  ADD_NOTIFICATION_FAILURE = 'ADD_NOTIFICATION_FAILURE',

  DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST',
  DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE',

  SET_PAGE_NUMBER = 'SET_PAGE_NUMBER',
  DELETE_PAGE_NUMBER = 'DELETE_PAGE_NUMBER',

  SET_PAGE_SIZE = 'SET_PAGE_SIZE',
  DELETE_PAGE_SIZE = 'DELETE_PAGE_SIZE',

  SET_SORT_BY = 'SET_SORT_BY',

  SET_LOADING = 'SET_LOADING',

  RESET_PAGINATION = 'RESET_PAGINATION',

  SET_WIDTH = 'SET_WIDTH',

  SET_SCROLL = 'SET_SCROLL',
}

export enum EPagination {
  currentPage = 'page',
  itemsPerPage = 'size',
  sortBy = 'sort',
  totalElements = 'totalElements',
  totalPages = 'totalPages',
}

export enum ESort {
  priceLow = 'price,asc',
  priceHigh = 'price,desc',
  ageOld = 'firstRegistrationYear,asc',
  ageNew = 'firstRegistrationYear,desc',
  mileageLow = 'mileage,asc',
  mileageHigh = 'mileage,desc',
  brandAZ = 'brand,asc',
  brandZA = 'brand,desc',
  distanceToCar = 'distance,asc',
  horsePowerLow = 'horsePower,asc',
  horsePowerHigh = 'horsePower,desc',
}

type ArticleAction = {
  type: EDataStore;
};

export type TDispatchData = (
  args: ArticleAction | ((dispatch: any) => Promise<any>)
) => ArticleAction;

export type TDispatchAuth = (args: TDispatchAuth) => ArticleAction;
