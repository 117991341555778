import React from 'react';
import { IRadioButton } from '../../../types';

import s from './RadioButton.module.scss';

const RadioButton = ({
  value,
  name,
  title,
  checked,
  onChange,
  id,
}: IRadioButton) => {
  return (
    <div className={s['RadioButton']}>
      <input
        type="radio"
        value={value}
        name={name}
        onChange={onChange}
        checked={checked}
        // defaultChecked={checked}
        id={id}
      />
      <label htmlFor={id}>{title}</label>
    </div>
  );
};

export default RadioButton;
