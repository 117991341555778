import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useTranslation } from 'react-i18next';
import { dataActions } from '../../store/actions/data.actions';
import { makeQuery } from '../../utils';
import { ERouterPath, IState, IStore } from '../../types';

const useStepper = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataStore: IStore = useSelector((state: IState) => state.data);
  const [active, setActive] = useState(1);

  useEffect(() => {
    dispatch(dataActions.resetPagination());
  }, []);

  const setActiveStep = (active: number) => {
    setActive(active);
  };

  const handleSearch = async () => {
    new Promise((resolve) =>
      resolve(
        dispatch(dataActions.setScroll({ x: 0, y: 0 }) as unknown as AnyAction)
      )
    ).then(() => {
      const query = makeQuery({
        ...dataStore.selectedFilters,
        ...dataStore.pagination,
      });
      navigate({ pathname: '/results', search: `?${query}` });
    });
  };

  const saveNotification = () => {
    navigate({ pathname: ERouterPath.SaveNotification });
  };

  const fetchCounterData = async (query: string) => {
    dispatch(dataActions.getAllCarsCount(query) as unknown as AnyAction);
  };

  useEffect(() => {
    if (dataStore.selectedFilters) {
      const query = makeQuery(dataStore.selectedFilters);
      fetchCounterData(query).then();
    } else {
      fetchCounterData('').then();
    }
  }, [dataStore.selectedFilters]);

  return {
    active,
    handleSearch,
    loading: dataStore.loading === undefined ? true : dataStore.loading,
    saveNotification,
    setActiveStep,
    t,
    total: dataStore.total,
    width: dataStore.width,
  };
};

export default useStepper;
