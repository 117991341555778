import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IState, IVehicle } from '../../types';
import { useEffect, useRef, useState } from 'react';
import { dataActions } from '../../store/actions/data.actions';
import { AnyAction } from 'redux';
import useOnScreen from '../../hooks/useOnScreen';

const useSinglePost = () => {
  const { id } = useParams();
  if (id === undefined) throw new Error('The vehicle id does not exist!');

  const dataStore = useSelector((state: IState) => state.data);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState<IVehicle>();

  const fetchSingleItem = (id: string) => {
    dispatch(dataActions.getSinglePost(id) as unknown as AnyAction);
  };

  useEffect(() => {
    fetchSingleItem(id);
  }, []);

  useEffect(() => {
    if (dataStore && dataStore.singlePost) {
      window.scrollTo(0, 0);
      setData(dataStore.singlePost);
    }
  }, [dataStore && dataStore.singlePost]);

  const [singleYear, setSingleYear] = useState('');
  const getFirstWord = (str: string) => {
    const spaceIndex = str.indexOf('-');
    return spaceIndex === -1 ? str : str.substr(0, spaceIndex);
  };

  useEffect(() => {
    if (data && data?.firstRegistration) {
      setSingleYear(getFirstWord(data?.firstRegistration));
    }
  }, [data?.firstRegistration]);

  const [scrollDir, setScrollDir] = useState(true);

  const ref = useRef(null);
  const onScreen = useOnScreen(ref, '-100px');

  const detectScroll = () => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;
    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY <= lastScrollY);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };
    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  };
  useEffect(() => {
    detectScroll();
  }, []);

  return { data, ref, onScreen, scrollDir, singleYear, t };
};

export default useSinglePost;
