import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { dataActions } from '../../store/actions/data.actions';
import { EDataStore, IState } from '../../types';
import { queryToObj } from '../../utils';

const useResults = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const dataStore = useSelector((state: IState) => state.data);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch({ type: EDataStore.SET_LOADING, loading: true });
    Promise.all([
      dispatch(
        dataActions.searchByFilterQuery(
          location.search
            ? location.search
            : `?&size=${dataStore.pagination?.size}&page=${dataStore.pagination?.page}`
        ) as unknown as AnyAction
      ),
      keycloak?.authenticated &&
        dispatch(dataActions.getUserFavorites() as unknown as AnyAction),

      dispatch(
        dataActions.selectedFilters(
          queryToObj(location.search)
        ) as unknown as AnyAction
      ),
    ]).then(() => {
      new Promise((resolve) =>
        resolve(dispatch({ type: EDataStore.SET_LOADING, loading: false }))
      ).then(() => {
        if (dataStore.scroll?.y) {
          window.scrollTo(0, dataStore.scroll.y);
        }
      });
    });
  };

  return {
    dataStore,
    loading: dataStore.loading === undefined ? true : dataStore.loading,
    t,
  };
};

export default useResults;
