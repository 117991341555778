import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { ERouterPath } from '../types';

const PrivateRoute = () => {
  const { keycloak } = useKeycloak();

  return keycloak?.authenticated ? (
    <Outlet />
  ) : (
    <Navigate to={ERouterPath.Login} replace />
  );
};

export default PrivateRoute;
