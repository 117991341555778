import { EDataStore } from '../../types';

const initial = {
  favourites: [],
  list: [],
  pagination: {
    page: 1,
    size: 12,
  },
  selectedFilters: {},
};

export const data = (state = initial, action: any) => {
  let newData = {};
  const refreshedData: any = state.selectedFilters;

  switch (action.type) {
    case EDataStore.DATA_REQUEST:
      return { ...state };
    case EDataStore.DATA_SUCCESS:
      return { ...state, list: action.data };
    case EDataStore.DATA_FAILURE:
      return { ...state };
    case EDataStore.SINGLE_POST_REQUEST:
      return { ...state };
    case EDataStore.SINGLE_POST_SUCCESS:
      return { ...state, singlePost: action.data };
    case EDataStore.SINGLE_POST_FAILURE:
      return { ...state };
    case EDataStore.FAVOURITES_REQUEST:
      return { ...state, loading: true };
    case EDataStore.FAVOURITES_SUCCESS:
      return {
        ...state,
        favourites: action.data._embedded?.allCarsRepresentationModelList,
        loading: false,
        pagination: {
          ...state.pagination,
          page: action.data.page.number || 1,
          size: action.data.page.size || 12,
          totalElements: action.data.page.totalElements,
          totalPages: action.data.page.totalPages,
        },
      };
    case EDataStore.FAVOURITES_FAILURE:
      return { ...state, loading: false };
    case EDataStore.SORT_REQUEST:
      return { ...state };
    case EDataStore.SORT_SUCCESS:
      return { ...state, favourites: action.data };
    case EDataStore.SORT_FAILURE:
      return { ...state };
    case EDataStore.SEARCH_REQUEST:
      return { ...state };
    case EDataStore.SEARCH_SUCCESS:
      return { ...state, list: action.data };
    case EDataStore.SEARCH_FAILURE:
      return { ...state };
    case EDataStore.BRAND_REQUEST:
      return { ...state };
    case EDataStore.BRAND_SUCCESS:
      return { ...state, brands: action.data };
    case EDataStore.BRAND_FAILURE:
      return { ...state };
    case EDataStore.MODEL_REQUEST:
      return { ...state };
    case EDataStore.MODEL_SUCCESS:
      return { ...state, models: action.data };
    case EDataStore.MODEL_FAILURE:
      return {
        ...state,
      };
    case EDataStore.GET_DATA_QUERY_REQUEST:
      return { ...state };
    case EDataStore.GET_DATA_QUERY_SUCCESS:
      return {
        ...state,
        list: action.data._embedded?.allCarsRepresentationModelList,
        pagination: {
          ...state.pagination,
          page: action.data.page.number || 1,
          size: action.data.page.size || 12,
          totalElements: action.data.page.totalElements,
          totalPages: action.data.page.totalPages,
        },
      };
    case EDataStore.GET_DATA_QUERY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case EDataStore.DATA_COUNT_QUERY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDataStore.DATA_COUNT_QUERY_SUCCESS:
      return {
        ...state,
        total: action.data,
        loading: false,
      };
    case EDataStore.DATA_COUNT_QUERY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case EDataStore.SELECTED_FILTERS_SET:
      if (Object.keys(action.data).length !== 0) {
        newData = { ...state.selectedFilters, ...action.data };
      }
      return {
        ...state,
        selectedFilters: newData,
      };
    case EDataStore.SELECTED_FILTERS_DELETE:
      if (Array.isArray(action.data)) {
        action.data.forEach((element: string) => {
          delete refreshedData[element];
        });
      } else {
        delete refreshedData[action.data];
      }
      return {
        ...state,
        selectedFilters: refreshedData,
      };

    case EDataStore.DELETE_NOTIFICATION_REQUEST:
      return { ...state, loading: true };
    case EDataStore.DELETE_NOTIFICATION_SUCCESS:
      return { ...state, loading: false };
    case EDataStore.DELETE_NOTIFICATION_FAILURE:
      return { ...state, loading: false };

    case EDataStore.NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case EDataStore.NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationList:
          action.data._embedded?.notificationsRepresentationModelList,
        loading: false,
      };
    case EDataStore.NOTIFICATIONS_FAILURE:
      return { ...state, loading: false };

    case EDataStore.ADD_NOTIFICATION_REQUEST:
      return { ...state, loading: true };
    case EDataStore.ADD_NOTIFICATION_SUCCESS:
      return { ...state, notificationList: action.data, loading: false };
    case EDataStore.ADD_NOTIFICATION_FAILURE:
      return { ...state, loading: false };

    case EDataStore.FAVORITE_IDS_REQUEST:
      return { ...state };
    case EDataStore.FAVORITE_IDS_SUCCESS:
      return { ...state, favoriteIds: action.data };
    case EDataStore.FAVORITE_IDS_FAILURE:
      return { ...state };

    case EDataStore.SET_PAGE_NUMBER:
      return {
        ...state,
        pagination: { ...state.pagination, page: action.data },
      };
    case EDataStore.DELETE_PAGE_NUMBER:
      return {
        ...state,
        pagination: { ...state.pagination, page: 1 },
      };
    case EDataStore.SET_PAGE_SIZE:
      return {
        ...state,
        pagination: { ...state.pagination, size: action.data },
      };
    case EDataStore.DELETE_PAGE_SIZE:
      return {
        ...state,
        pagination: { ...state.pagination, page: 1, size: 12 },
      };
    case EDataStore.SET_SORT_BY:
      return {
        ...state,
        pagination: { ...state.pagination, sort: action.data },
      };
    case EDataStore.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EDataStore.RESET_PAGINATION:
      return {
        ...state,
        pagination: { ...state.pagination, page: 1 },
      };
    case EDataStore.SET_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case EDataStore.SET_SCROLL:
      return {
        ...state,
        scroll: action.data,
      };
    default:
      return state;
  }
};
