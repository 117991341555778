import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { dataActions } from '../../store/actions/data.actions';
import { getId } from '../../utils';
import { EDataStore, ERouterPath, IState, IVehicle } from '../../types';

const useMark = (item: IVehicle) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataStore = useSelector((state: IState) => state.data);
  const { keycloak } = useKeycloak();
  const [marked, setMark] = useState(false);

  useEffect(() => {
    if (dataStore.favoriteIds) {
      const adId = getId(item._links.self.href);
      const value = dataStore.favoriteIds.includes(parseInt(adId) as never);
      setMark(value);
    }
  }, []);

  const handleMark = async () => {
    const adId = getId(item._links.self.href);
    if (keycloak?.authenticated) {
      setMark(true);
      dispatch(dataActions.mark(adId, item.originalId) as unknown as AnyAction);
    } else {
      navigate(ERouterPath.Login);
    }
  };

  const handleUnmark = async () => {
    const adId = getId(item._links.self.href);
    if (keycloak?.authenticated) {
      dispatch({ type: EDataStore.SET_LOADING, loading: true });
      setMark(false);
      new Promise((resolve) =>
        resolve(dispatch(dataActions.unmark(adId) as unknown as AnyAction))
      ).then(() => {
        if (location.pathname === ERouterPath.Favourites) {
          dispatch(
            dataActions.getFavourites(
              location.search
                ? location.search
                : `?size=${dataStore.pagination?.size}&page=${dataStore.pagination?.page}`
            ) as unknown as AnyAction
          );
        }
      });
    } else {
      navigate(ERouterPath.Login);
    }
  };

  return { handleMark, handleUnmark, marked, t };
};

export default useMark;
