import React, { ChangeEvent, useState } from 'react';
import ReactLoading from 'react-loading';
import useProfile from './useProfile';
import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';
import authService from '../../services/auth.service';

import s from './Profile.module.scss';

const ExportUsers = () => {
  const { re, t } = useProfile();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setEmail(e.target.value);
  };

  const handleExportUsers = () => {
    if (!re.test(email.toLowerCase())) {
      return setError('errMsg.emailValid');
    }

    setLoading(true);
    authService.exportUsersApi(email).then(
      () => {
        setEmail('');
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  return (
    <div className={s['ExportUsers']}>
      {loading ? (
        <ReactLoading
          type={'spokes'}
          color={'#FFA217'}
          height={32}
          width={32}
          className="loader"
        />
      ) : (
        <div className={s['Item']}>
          <p>{t('profile.exportUsers')}</p>

          <div className={s['Input']}>
            <Input
              inputClass="InputPrimary"
              name={'exportUsers'}
              value={email}
              onChange={handleEmailChange}
              placeholder={t('profile.email')}
            />
          </div>

          {error.length ? (
            <div className="input-err-msg">
              <span>*{t(error)}</span>
            </div>
          ) : null}

          <div className={s['Options']}>
            <Button
              btnClass="btnPrimary"
              label={t('btn.export')}
              onClick={handleExportUsers}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportUsers;
