import React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import './Carousel.module.scss';

const CarouselBlog = ({ data }: { data: readonly ReactImageGalleryItem[] }) => {
  return (
    <div className="blog-carousel-wrapper">
      <div className="blog-carousel-web">
        <ImageGallery
          items={data}
          showPlayButton={false}
          showFullscreenButton={false}
          thumbnailPosition={'right'}
          showNav={false}
          showBullets={true}
          autoPlay={true}
        />
      </div>
      <div className="blog-carousel-mobile">
        <ImageGallery
          items={data}
          showPlayButton={false}
          showFullscreenButton={false}
          showNav={false}
          showBullets={true}
          autoPlay={true}
        />
      </div>
    </div>
  );
};

export default CarouselBlog;
