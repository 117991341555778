import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import keycloak from './keycloak';
import { ELanguage } from './types';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: keycloak.token || ELanguage.En,
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
    },
    fallbackLng: keycloak.token || ELanguage.En,
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: false,
    },
  })
  .then();

export default i18n;
