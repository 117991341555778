import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { authActions } from '../../store/actions/auth.actions';
import { ISelect, IState, IUser } from '../../types';
import authService from '../../services/auth.service';
import { urlWeb } from '../../environment';
import { useKeycloak } from '@react-keycloak/web';

const useProfile = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const authStore = useSelector((state: IState) => state.auth);
  const [user, setUser] = useState<IUser>();
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-ZäöüÄÖÜß\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const fetchData = () => {
    dispatch(authActions.login() as unknown as AnyAction);
  };

  useEffect(() => {
    if (authStore && authStore.user) {
      setUser(authStore.user);
    } else {
      fetchData();
    }
  }, [authStore && authStore.user]);

  const deleteAccount = () => {
    if (user === undefined) return;
    setIsModalOpen(false);

    authService.deleteUserApi().then(
      async () => {
        dispatch(authActions.logout() as unknown as AnyAction);
        await keycloak.logout({ redirectUri: urlWeb }).then();
      },
      () => setIsModalOpen(false)
    );
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (user === undefined) return;
    setError('');
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (e: ISelect, type: string) => {
    if (user === undefined) return;
    setError('');
    setUser({
      ...user,
      [type]: e.value,
    });
  };

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setError('');

    if (
      user === undefined ||
      !user.email ||
      !user.secondEmail ||
      !user.postalCode ||
      !user.city ||
      !user.country
    ) {
      return setError('errMsg.allFields');
    }

    if (
      !re.test(user.email.toLowerCase()) ||
      !re.test(user.secondEmail.toLowerCase())
    ) {
      return setError('errMsg.emailValid');
    }

    const response = dispatch(authActions.update(user) as unknown as AnyAction);
    if (response) {
      i18n
        .changeLanguage(user.language?.toLowerCase())
        .then(() => setEditMode(false));
    }
  };

  const toggleEdit = () => {
    setEditMode(true);
  };

  return {
    deleteAccount,
    editMode,
    error,
    handleCancel,
    handleInputChange,
    handleSelectChange,
    handleSubmit,
    isModalOpen,
    loading: authStore.loading !== undefined ? authStore.loading : true,
    re,
    setIsModalOpen,
    t,
    toggleEdit,
    user,
  };
};

export default useProfile;
