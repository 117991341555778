import { EAuthStore } from '../../types';

const initialState = {};

export const auth = (state = initialState, action: any) => {
  switch (action.type) {
    case EAuthStore.LOGIN_REQUEST:
      return { ...state, loading: true };
    case EAuthStore.LOGIN_SUCCESS:
      return { ...state, user: action.user, loading: false };
    case EAuthStore.LOGIN_FAILURE:
      return { ...state, loading: false };
    case EAuthStore.LOGOUT_REQUEST:
      return { ...state };
    case EAuthStore.LOGOUT_SUCCESS:
      return {};
    case EAuthStore.LOGOUT_FAILURE:
      return { ...state };
    case EAuthStore.UPDATE_REQUEST:
      return { ...state };
    case EAuthStore.UPDATE_SUCCESS:
      return { ...state, loading: false, user: action.user };
    case EAuthStore.UPDATE_FAILURE:
      return { ...state };
    default:
      return state;
  }
};
