import React from 'react';
import Selector from '../Selector';
import useSortBy from './useSortBy';
import { ERouterPath, ISelect } from '../../../types';

import s from './SortBy.module.scss';

const SortBy = ({ pathname }: { pathname: ERouterPath }) => {
  const { getSelectedSortBy, handleSelectSortBy, SORT, t } =
    useSortBy(pathname);

  return (
    <div className={s['Sort']}>
      <Selector
        additionalClass={s['Selector']}
        selectClassname="selectFilter"
        options={SORT}
        handleChangeSelect={(e: ISelect) => handleSelectSortBy(e)}
        placeholder={t('label.sort')}
        selectedOption={getSelectedSortBy()}
      />
    </div>
  );
};

export default SortBy;
