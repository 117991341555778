import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ELanguage } from '../../types';

import s from './LanguageSelector.module.scss';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(event.target.value).then();
  };

  return (
    <select
      onChange={changeLanguage}
      className={s['LanguageSelector']}
      value={i18n.language.toLowerCase()}
    >
      <Emoji value={ELanguage.En} label={ELanguage.En} symbol="Language:" />
      <Emoji value={ELanguage.De} label={ELanguage.De} symbol="Sprache:" />
      <Emoji value={ELanguage.It} label={ELanguage.It} symbol="Lingua:" />
      <Emoji value={ELanguage.Fr} label={ELanguage.Fr} symbol="Langue:" />
    </select>
  );
};

const Emoji = ({
  value,
  label,
  symbol,
}: {
  value: string;
  label: string;
  symbol: string;
}) => (
  <option
    className="emoji"
    role="img"
    // aria-label={label ? label : ''}
    // aria-hidden={label ? 'false' : 'true'}
    value={value}
  >
    {symbol} {label.toUpperCase()}
  </option>
);

export default LanguageSelector;
