import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import dataService from '../../services/data.service';
import {
  EDataStore,
  ICarResponse,
  IFilters,
  IKey,
  INotificationResponse,
  TDispatchData,
} from '../../types';

const getSinglePost = (id: string) => {
  const request = () => {
    return { type: EDataStore.SINGLE_POST_REQUEST };
  };
  const success = (data: any) => {
    return { type: EDataStore.SINGLE_POST_SUCCESS, data };
  };
  const failure = (error: Error) => {
    return { type: EDataStore.SINGLE_POST_FAILURE, error };
  };
  return (dispatch: TDispatchData) => {
    dispatch(request());
    return dataService.getSinglePost(id).then(
      (result) => {
        dispatch(success(result));
        return result;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const getFavourites = (query: string) => {
  const request = () => {
    return { type: EDataStore.FAVOURITES_REQUEST };
  };
  const success = (data: any) => {
    return { type: EDataStore.FAVOURITES_SUCCESS, data };
  };
  const failure = (error: Error) => {
    return { type: EDataStore.FAVOURITES_FAILURE, error };
  };
  return (dispatch: TDispatchData) => {
    dispatch(request());
    return dataService.getFavourites(query).then(
      (result) => {
        dispatch(success(result));
        return result;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const mark = (adId: string, originalId: string) => {
  return async (dispatch: TDispatchData) => {
    const result = await dataService.mark(adId, originalId);
    dispatch(getUserFavorites());
    return result;
  };
};

const unmark = (adId: string) => {
  return async (dispatch: TDispatchData) => {
    const result = await dataService.unmark(adId);
    dispatch(getUserFavorites());
    return result;
  };
};

const getCarsBrands = () => {
  const request = () => {
    return { type: EDataStore.BRAND_REQUEST };
  };
  const success = (data: any) => {
    return { type: EDataStore.BRAND_SUCCESS, data };
  };
  const failure = (error: Error) => {
    return { type: EDataStore.BRAND_FAILURE, error };
  };
  return (dispatch: TDispatchData) => {
    dispatch(request());
    return dataService.getCarsBrandsApi().then(
      (result) => {
        dispatch(success(result));
        return result;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const getModels = (brand: string) => {
  const request = () => {
    return { type: EDataStore.MODEL_REQUEST };
  };
  const success = (data: any) => {
    return { type: EDataStore.MODEL_SUCCESS, data };
  };
  const failure = (error: Error) => {
    return { type: EDataStore.MODEL_FAILURE, error };
  };

  return (dispatch: TDispatchData) => {
    dispatch(request());
    return dataService.getModelsApi(brand).then(
      (result) => {
        dispatch(success(result));
        return result;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const selectedFilters = (data: IFilters) => {
  const success = (data: IFilters) => {
    return { type: EDataStore.SELECTED_FILTERS_SET, data };
  };
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(success(data));
    return data;
  };
};

const deleteCertainFilter = (data: any) => {
  const success = (data: any) => {
    return { type: EDataStore.SELECTED_FILTERS_DELETE, data };
  };
  return (dispatch: TDispatchData) => {
    dispatch(success(data));
    return data;
  };
};

const searchByFilterQuery = (query: string) => {
  const request = () => {
    return { type: EDataStore.GET_DATA_QUERY_REQUEST };
  };
  const success = (data: ICarResponse) => {
    return { type: EDataStore.GET_DATA_QUERY_SUCCESS, data };
  };
  const failure = (error: Error) => {
    return { type: EDataStore.GET_DATA_QUERY_FAILURE, error };
  };
  return (dispatch: TDispatchData) => {
    dispatch(request());
    return dataService.searchByFilterQuery(query).then(
      (result) => {
        dispatch(success(result as unknown as ICarResponse));
        return result;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const getAllCarsCount = (query: string) => {
  const request = () => {
    return { type: EDataStore.DATA_COUNT_QUERY_REQUEST };
  };
  const success = (data: number) => {
    return { type: EDataStore.DATA_COUNT_QUERY_SUCCESS, data };
  };
  const failure = (error: Error) => {
    return { type: EDataStore.DATA_COUNT_QUERY_FAILURE, error };
  };
  return (dispatch: TDispatchData) => {
    dispatch(request());
    return dataService.getAllCarsCountApi(query).then(
      (result) => {
        dispatch(success(result as unknown as number));
        return result;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const getAllNotifications = () => {
  const request = () => {
    return { type: EDataStore.NOTIFICATIONS_REQUEST };
  };
  const success = (data: INotificationResponse) => {
    return { type: EDataStore.NOTIFICATIONS_SUCCESS, data };
  };
  const failure = (error: Error) => {
    return { type: EDataStore.NOTIFICATIONS_FAILURE, error };
  };
  return (dispatch: TDispatchData) => {
    dispatch(request());
    return dataService.getAllNotificationsApi().then(
      (result) => {
        dispatch(success(result as unknown as INotificationResponse));
        return result;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};
const addNotification = (data: IKey) => {
  const request = () => {
    return { type: EDataStore.ADD_NOTIFICATION_REQUEST };
  };
  const success = (data: IKey) => {
    return { type: EDataStore.ADD_NOTIFICATION_SUCCESS, data };
  };
  const failure = (error: Error) => {
    return { type: EDataStore.ADD_NOTIFICATION_FAILURE, error };
  };
  return (dispatch: TDispatchData) => {
    dispatch(request());
    return dataService.addNotification(data).then(
      (result) => {
        dispatch(success(result as unknown as IKey));
        return result;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const deleteNotification = (adId: any) => {
  const request = () => {
    return { type: EDataStore.DELETE_NOTIFICATION_REQUEST };
  };
  const success = (data: any) => {
    return { type: EDataStore.DELETE_NOTIFICATION_SUCCESS, data };
  };
  const failure = (error: Error) => {
    return { type: EDataStore.DELETE_NOTIFICATION_FAILURE, error };
  };
  return (dispatch: TDispatchData) => {
    dispatch(request());
    return dataService.deleteNotification(adId).then(
      (result) => {
        dispatch(success(result));
        dispatch(getAllNotifications());
        return result;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const getUserFavorites = () => {
  const request = () => {
    return { type: EDataStore.FAVORITE_IDS_REQUEST };
  };
  const success = (data: any) => {
    return { type: EDataStore.FAVORITE_IDS_SUCCESS, data };
  };
  const failure = (error: Error) => {
    return { type: EDataStore.FAVORITE_IDS_FAILURE, error };
  };
  return (dispatch: TDispatchData) => {
    dispatch(request());
    return dataService.getUserFavorites().then(
      (result) => {
        dispatch(success(result));
        return result;
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
};

const setSortBy = (data: string) => {
  return { type: EDataStore.SET_SORT_BY, data };
};

const resetPagination = () => {
  return { type: EDataStore.RESET_PAGINATION };
};

const setWidth = (width: number) => {
  return { type: EDataStore.SET_WIDTH, width };
};

const setScroll = (data: { x: number; y: number }) => {
  return { type: EDataStore.SET_SCROLL, data };
};

export const dataActions = {
  getFavourites,
  mark,
  getSinglePost,
  getModels,
  selectedFilters,
  searchByFilterQuery,
  getAllCarsCount,
  deleteCertainFilter,
  getAllNotifications,
  addNotification,
  getCarsBrands,
  unmark,
  deleteNotification,
  getUserFavorites,
  setSortBy,
  resetPagination,
  setWidth,
  setScroll,
};
