import React, { Fragment } from 'react';
import ReactLoading from 'react-loading';
import useFavourites from './useFavourites';
import Pagination from '../../components/UI/Pagination';
import SortBy from '../../components/UI/SortBy/SortBy';
import GoogleAd from '../../components/Ad';
import Card from '../../components/Card';
import { getId } from '../../utils';
import { ECardType, ERouterPath, IVehicle } from '../../types';

const Favourites = () => {
  const { data, loading, pagination, t } = useFavourites();

  return (
    <div className="container">
      {loading ? (
        <ReactLoading
          type={'spokes'}
          color={'#FFA217'}
          height={60}
          width={60}
          className="loader"
        />
      ) : (
        <div>
          {data?.length > 0 ? (
            <div>
              <SortBy pathname={ERouterPath.Favourites} />

              {data.map((item: IVehicle, index: number) => {
                return (
                  <Fragment key={getId(item._links.self.href)}>
                    <Card
                      item={item}
                      cardType={ECardType.cardPrimary}
                      key={getId(item._links.self.href)}
                    />
                    {(index + 1) % 4 == 0 && (
                      <div className="ads">
                        <section>
                          <GoogleAd />
                        </section>
                      </div>
                    )}
                  </Fragment>
                );
              })}

              <Pagination
                defaultPageSize={pagination.size}
                pathname={ERouterPath.Favourites}
                total={
                  pagination.totalElements
                    ? pagination.totalElements
                    : data?.length
                }
              />
            </div>
          ) : (
            <h1>{t('no.results.favorites')} </h1>
          )}
        </div>
      )}
    </div>
  );
};

export default Favourites;
