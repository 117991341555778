import { useTranslation } from 'react-i18next';
import img1 from '../../assets/images/carousel/Corvette1.jpg';
import img2 from '../../assets/images/carousel/Corvette2.jpg';
import img3 from '../../assets/images/carousel/Corvette3.jpg';
import img4 from '../../assets/images/carousel/Corvette4.jpg';
import img5 from '../../assets/images/carousel/Corvette5.jpg';
import img6 from '../../assets/images/carousel/Corvette6.jpg';
import img7 from '../../assets/images/carousel/Corvette7.jpg';
import img8 from '../../assets/images/carousel/Corvette8.jpg';
import img9 from '../../assets/images/carousel/Corvette9.jpg';
import img10 from '../../assets/images/carousel/Corvette10.jpg';
import img11 from '../../assets/images/carousel/Corvette11.jpg';
import img12 from '../../assets/images/carousel/Corvette12.jpg';
import img13 from '../../assets/images/carousel/Corvette13.jpg';
import img14 from '../../assets/images/carousel/Corvette14.jpg';
import img15 from '../../assets/images/carousel/Corvette15.jpg';
import img16 from '../../assets/images/carousel/Yaris1.jpg';
import img17 from '../../assets/images/carousel/Yaris2.jpg';

const useUnmark = () => {
  const { t } = useTranslation();

  const corvetteImages = [
    { original: img1, thumbnail: img1 },
    {
      original: img2,
      thumbnail: img2,
    },
    {
      original: img3,
      thumbnail: img3,
    },
    {
      original: img4,
      thumbnail: img4,
    },
    {
      original: img5,
      thumbnail: img5,
    },
    {
      original: img6,
      thumbnail: img6,
    },
    {
      original: img7,
      thumbnail: img7,
    },
    {
      original: img8,
      thumbnail: img8,
    },
    {
      original: img9,
      thumbnail: img9,
    },
    {
      original: img10,
      thumbnail: img10,
    },
    {
      original: img11,
      thumbnail: img11,
    },
    {
      original: img12,
      thumbnail: img12,
    },
    {
      original: img13,
      thumbnail: img13,
    },
    {
      original: img14,
      thumbnail: img14,
    },
    {
      original: img15,
      thumbnail: img15,
    },
  ];

  const toyotaImages = [
    {
      original: img16,
      thumbnail: img16,
    },
    {
      original: img17,
      thumbnail: img17,
    },
  ];

  return { corvetteImages, toyotaImages, t };
};

export default useUnmark;
