import React from 'react';
import ReactLoading from 'react-loading';
import keycloak from '../../keycloak';
import useProfile from './useProfile';
import Button from '../../components/UI/Button';
import Selector from '../../components/UI/Selector';
import GoogleAd from '../../components/Ad';
import ExportUsers from './ExportUsers';
import ProfileFormItem from './PofileFormItem';
import Modal from '../../components/Modal';
import { ISelect } from '../../types';
import { LANGUAGES } from '../../constants';
import { ProfileImage, UserIcon } from '../../assets/images';

import s from './Profile.module.scss';

const Profile = () => {
  const {
    deleteAccount,
    editMode,
    error,
    handleCancel,
    handleInputChange,
    handleSelectChange,
    handleSubmit,
    isModalOpen,
    loading,
    setIsModalOpen,
    t,
    toggleEdit,
    user,
  } = useProfile();

  return loading ? (
    <ReactLoading
      type={'spokes'}
      color={'#FFA217'}
      height={60}
      width={60}
      className="loader"
    />
  ) : user ? (
    <div className="container">
      <div className={s['Wrapper']}>
        <div className={s['Container']}>
          <div className={s['Title']}>
            <img alt={''} src={UserIcon} />
            <h2>{t('profile.title')}</h2>
          </div>
          <div className={s['Info']}>
            <div className={`${s['Basic']} ${editMode ? s['NoPadding'] : ''} `}>
              <div className={s['Item']}>
                <p>{t('profile.username')}:</p>
                <p>{user.username ? user.username : 'N/A'}</p>
              </div>

              <ProfileFormItem
                editMode={editMode}
                handleInputChange={handleInputChange}
                isRequired={true}
                label="profile.email"
                name="email"
                placeholder="placeholder.email"
                type="email"
                value={user.email}
              />

              <ProfileFormItem
                editMode={editMode}
                handleInputChange={handleInputChange}
                isRequired={true}
                label="profile.email.secondary"
                name="secondEmail"
                placeholder="placeholder.email2"
                type="email"
                value={user.secondEmail}
              />
            </div>
            <div
              className={`${s['Basic']} ${
                editMode ? `${s['NoPadding']}` : ''
              } `}
            >
              <ProfileFormItem
                editMode={editMode}
                handleInputChange={handleInputChange}
                isRequired={true}
                label="profile.zipCode"
                name="postalCode"
                placeholder="placeholder.zipCode"
                type="number"
                value={user.postalCode}
              />

              <ProfileFormItem
                editMode={editMode}
                handleInputChange={handleInputChange}
                isRequired={true}
                label="profile.city"
                name="city"
                placeholder="placeholder.city"
                type="text"
                value={user.city}
              />

              <ProfileFormItem
                editMode={editMode}
                handleInputChange={handleInputChange}
                isRequired={true}
                label="profile.country"
                name="country"
                placeholder="placeholder.country"
                type="text"
                value={user.country}
              />

              <div className={s['Item']}>
                <p>{t('profile.language')}*:</p>
                {!editMode ? (
                  <p>{user.language ? user.language : 'N/A'}</p>
                ) : (
                  <div className={s['EditMode']}>
                    <Selector
                      additionalClass={s['Selector']}
                      handleChangeSelect={(e: ISelect) =>
                        handleSelectChange(e, 'language')
                      }
                      options={LANGUAGES}
                      placeholder={t('profile.language')}
                      selectClassname="selectFilter"
                      selectedOption={
                        LANGUAGES.find(
                          (language) =>
                            language.value.toLowerCase() ===
                            user.language?.toLowerCase()
                        ) || undefined
                      }
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={`${s['Basic']} ${editMode ? s['NoPadding'] : ''} `}>
              <ProfileFormItem
                editMode={editMode}
                handleInputChange={handleInputChange}
                label="profile.phone"
                name="phoneNumber"
                placeholder="placeholder.phone"
                type="number"
                value={user.phoneNumber}
              />

              <ProfileFormItem
                editMode={editMode}
                handleInputChange={handleInputChange}
                label="profile.mobile"
                name="mobilePhoneNumber"
                placeholder="placeholder.mobile"
                type="number"
                value={user.mobilePhoneNumber}
              />

              {error.length ? (
                <div className="input-err-msg">
                  <span>*{t(error)}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className={s['Options']}>
            {editMode ? (
              <>
                <Button
                  btnClass="btnGrey"
                  label={t('btn.cancel')}
                  onClick={handleCancel}
                />
                <Button
                  btnClass="btnPrimary"
                  label={t('btn.save')}
                  onClick={handleSubmit}
                />
              </>
            ) : (
              <>
                <Button
                  btnClass="btnPrimary"
                  label={t('btn.change')}
                  onClick={toggleEdit}
                />
                <Button
                  btnClass="btnGrey"
                  label={t('btn.delete')}
                  onClick={() => setIsModalOpen(true)}
                />
              </>
            )}
          </div>

          {keycloak.tokenParsed?.realm_access?.roles.includes('admin') && (
            <ExportUsers />
          )}

          {isModalOpen && (
            <Modal
              handleCancel={() => setIsModalOpen(false)}
              handleSubmit={deleteAccount}
              title="modal.title.deleteAccount"
              text="modal.text.deleteAccount"
            />
          )}
        </div>
        <div className={s['ProfileImg']}>
          <img alt={''} src={ProfileImage} />
        </div>
      </div>
      <div className="ads">
        <section>
          <GoogleAd />
        </section>
      </div>
    </div>
  ) : (
    <div>User does not exist!</div>
  );
};

export default Profile;
