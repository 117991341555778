import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useKeycloak } from '@react-keycloak/web';
import { dataActions } from '../../store/actions/data.actions';
import { IState } from '../../types';

const useNotifications = () => {
  const { t } = useTranslation();
  const dataStore = useSelector((state: IState) => state.data);
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak.authenticated) {
      new Promise((resolve) =>
        resolve(
          dispatch(dataActions.getAllNotifications() as unknown as AnyAction)
        )
      ).then();
    }
  }, []);

  return {
    loading: dataStore.loading === undefined ? true : dataStore.loading,
    notifications: dataStore.notificationList || [],
    t,
  };
};

export default useNotifications;
