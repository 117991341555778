import httpService from './http.service';
import { EMethod, IKey } from '../types';

class authService {
  async getUser() {
    return httpService?.apiRequest(EMethod.GET, '/users/user');
  }

  async deleteUserApi() {
    return httpService.apiRequest(EMethod.DELETE, '/users/user');
  }

  async update(user: IKey) {
    return httpService.apiRequest(EMethod.POST, '/users/update', user);
  }

  async logout() {
    return httpService.apiRequest(EMethod.GET, '/users/logout');
  }

  async exportUsersApi(email: string) {
    return httpService.apiRequest(
      EMethod.GET,
      `/users/export-users?recipient=${email}`
    );
  }
}

export default new authService();
