import React from 'react';
import { useSelector } from 'react-redux';
import NavbarMob from './NavbarMob';
import NavbarWeb from './NavbarWeb';
import { IState } from '../../types';

const Navbar = () => {
  const dataStore = useSelector((state: IState) => state.data);

  if (dataStore.width >= 1024) {
    return <NavbarWeb />;
  } else {
    return <NavbarMob />;
  }
};

export default Navbar;
