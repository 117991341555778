import { EFilter } from '../types';

export const STEP_2_TYPES = [
  'brand',
  'model',
  'fuel',
  'priceFrom',
  'priceTo',
  'mileageFrom',
  'mileageTo',
  'firstRegistrationYearFrom',
  'firstRegistrationYearTo',
  'cubicCapacityFrom',
  'cubicCapacityTo',
  'horsePowerFrom',
  'horsePowerTo',
];

export const STEP_3_TYPES = [
  'gearbox',
  'driveType',
  'entryDateFrom',
  'entryDateTo',
  'bodyColor',
  'postalCode',
  EFilter.latitude,
  EFilter.longitude,
  EFilter.country,
  EFilter.location,
  EFilter.radius,
];
