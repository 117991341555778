import React from 'react';

const NoMatch = () => {
  return (
    <div className="container">
      <p>No Match Page</p>
    </div>
  );
};

export default NoMatch;
