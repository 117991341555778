import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import './i18n';
import App from './App';
import { store } from './store/store';
import keycloak from './keycloak';
import '../node_modules/font-awesome/css/font-awesome.min.css';

import './assets/styles/index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ReactKeycloakProvider authClient={keycloak}>
    {/*<React.StrictMode>*/}
    <Provider store={store}>
      <App />
    </Provider>
    {/*</React.StrictMode>*/}
  </ReactKeycloakProvider>
);
