import React from 'react';
import useSinglePost from './useSinglePost';
import Button from '../../components/UI/Button';
import Carousel from '../../components/Carousel/Carousel';
import Card from '../../components/Card';
import Mark from '../../components/Mark';
import GoogleAd from '../../components/Ad';
import {
  SinglePostFeatures,
  SinglePostTechData,
} from '../../components/SinglePost';
import { formatPrice } from '../../utils';
import { ShareIcon } from '../../assets/images';
import { ECardType } from '../../types';

import styles from './SinglePost.module.scss';

const SinglePost = () => {
  const { data, ref, onScreen, scrollDir, singleYear, t } = useSinglePost();

  if (data === undefined) return null;

  return (
    <div className={styles.singlePageWrapper}>
      <div className={!scrollDir && !onScreen ? styles.sticky : styles.hidden}>
        <Card item={data} cardType={ECardType.cardSecondary} />
      </div>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.Header}>
            <div className={styles.Title} ref={ref}>
              <h2>{formatPrice(data.price)}</h2>
              <h1>
                {data.brand} {data.model}
              </h1>
            </div>
          </div>

          <div className={styles.container}>
            {data.images ? <Carousel data={data.images} /> : null}
            <div className={styles.content}>
              <div className={styles.options}>
                {data.firstRegistration ? <h2> {singleYear} </h2> : null}
                <div className={styles.icons}>
                  <Mark type="icon" item={data} />
                  <a href={`mailto:?body=${window.location.href}`}>
                    <img alt={''} src={ShareIcon} />
                  </a>
                </div>
              </div>
              <h4>{data.title}</h4>
              {data.description && data.description !== 'null' ? (
                <p>{data.description}</p>
              ) : (
                <p></p>
              )}
              <div className={styles.contact}>
                <div className={styles.contactInfo}>
                  <h4>{t('single.post.contact')}</h4>
                  {/*<p>*/}
                  {/*  {t('single.post.name')}: {data.contact}*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*  {t('single.post.phone')}: {data.phone}*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*  {t('single.post.address')}: {data?.city} {data?.contact}*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*  {t('single.post.email')}: {data.dataOriginSource}*/}
                  {/*</p>*/}
                  <p>{data.dataOriginSource}</p>
                </div>
                <div className={styles.info}>
                  {/* <a href={`tel:${data.phone}`}><Button btnClass="btnPrimary" label={t('btn.call')} /></a> */}
                  <a href={data.contact} rel="noreferrer" target="_blank">
                    <Button
                      btnClass="btnPrimary"
                      label={t('btn.originalInsertion')}
                    />
                  </a>
                  {/* <a href={`mailto:${data.mail}`} target="_blank"><Button btnClass="btnPrimary" label={t('btn.email')} /></a> */}
                </div>
              </div>
            </div>
          </div>

          <div className="Row">
            <div className="Col Col-6">
              <div className="ads">
                <section>
                  <GoogleAd />
                </section>
              </div>
            </div>
            <div className="Col Col-6">
              <div className="ads">
                <section>
                  <GoogleAd />
                </section>
              </div>
            </div>
          </div>

          <div className="Row">
            <div className="Col Col-7">
              <SinglePostTechData data={data} />
            </div>

            <div className="Col Col-5">
              <div className="ads" style={{ height: '420px' }}>
                <section>
                  <GoogleAd />
                </section>
              </div>
              <div className="ads" style={{ height: '420px' }}>
                <section>
                  <GoogleAd />
                </section>
              </div>
            </div>
          </div>
          <div className="ads">
            <section>
              <GoogleAd />
            </section>
          </div>
          {data.features && (
            <div className={styles.layout}>
              <SinglePostFeatures data={data.features} />
              <div className={styles.webAds}>
                <section>
                  <GoogleAd />
                </section>
              </div>
            </div>
          )}
          <div className="ads">
            <section>
              <GoogleAd />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
