import React, { ChangeEvent } from 'react';
import useStep3 from './useStep3';
import Button from '../../UI/Button';
import RadioButton from '../../UI/RadioButton';
import Selector from '../../UI/Selector';
import ColorPicker from '../../UI/ColorPicker';
import Autocomplete from '../../UI/Autocomplete';
import { EFilter, ISelect } from '../../../types';
import { COLOR_OPTIONS, ENTRY_DATE_STRING } from '../../../utils';
import {
  COUNTRIES,
  DISTANCE,
  DRIVE_TYPE,
  ENTRY_DATE,
  GEARBOX,
} from '../../../constants';

import s from '../Stepper.module.scss';

const Step3 = () => {
  const {
    filters,
    handleRadioChange,
    handleColorChange,
    handleSelectChange,
    getSelectedValue,
    resetFilter,
    selectedColors,
    t,
  } = useStep3();

  return (
    <div className={s['Step3']}>
      <div className="Row">
        <div className="Col Col-4">
          <label>{t('label.gearbox')}</label>
          {GEARBOX.map((item, i) => {
            return (
              <RadioButton
                key={item.id}
                value={item.id}
                name={item.name}
                title={t(item.title)}
                checked={item.id === filters.gearbox}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleRadioChange(e)
                }
                id={'gearbox_' + i}
              />
            );
          })}
        </div>
        <div className="Col Col-8">
          <label>{t('label.driveType')}</label>
          {DRIVE_TYPE.map((item, i) => {
            return (
              <RadioButton
                key={item.id}
                value={item.id}
                name={item.name}
                checked={item.id === filters[item.name]}
                title={t(item.title)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleRadioChange(e)
                }
                id={'driveType_' + i}
              />
            );
          })}
        </div>

        <div className="Col Col-3">
          <Selector
            handleChangeSelect={(e: ISelect) =>
              handleSelectChange(e, EFilter.country)
            }
            isClearable={true}
            label={t('label.country')}
            options={COUNTRIES}
            placeholder={t('label.country')}
            selectClassname="selectFilter"
            selectedOption={getSelectedValue(COUNTRIES, EFilter.country)}
          />
        </div>

        <div className="Col Col-3">
          <Autocomplete
            id="inpPostalCode"
            label="Postal Code"
            placeholder={`e.g. Bern ${t('or')} 3011`}
          />
        </div>

        <div className="Col Col-3">
          <Selector
            isDisabled={!filters.location?.length}
            handleChangeSelect={(e: ISelect) =>
              handleSelectChange(e, EFilter.radius)
            }
            isClearable={true}
            label={t('label.distance')}
            options={DISTANCE}
            placeholder={t('label.distance')}
            selectClassname="selectFilter"
            selectedOption={getSelectedValue(DISTANCE, EFilter.radius)}
          />
        </div>

        <div className="Col">
          <label>{t('bodyColor')}</label>
          <div className={s['Colors']}>
            {COLOR_OPTIONS.map((item) => {
              return (
                <ColorPicker
                  selected={
                    !!(selectedColors && selectedColors.includes(item.id))
                  }
                  color={item.color}
                  label={item.title}
                  key={item.id}
                  onClick={() => handleColorChange(item.id)}
                />
              );
            })}
          </div>
        </div>

        <div className="Col">
          <label>{t('label.date')}</label>
          {ENTRY_DATE.map((item, i) => {
            return (
              <RadioButton
                key={item.id}
                value={item.id}
                name={item.name}
                title={t(item.title)}
                checked={item.id === ENTRY_DATE_STRING[filters.entryDateFrom]}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleRadioChange(e)
                }
                id={'entryDateTo_' + i}
              />
            );
          })}
        </div>
      </div>

      <div className={s['Reset']}>
        <Button
          btnClass="btnPrimary"
          label={t('btn.reset')}
          onClick={() => resetFilter()}
        />
      </div>
    </div>
  );
};

export default Step3;
