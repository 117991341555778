import React from 'react';
import useBlog from './useBlog';
import CarouselBlog from '../../components/Carousel/CarouselBlog';

import styles from './Blog.module.scss';

const Blog = () => {
  const { corvetteImages, toyotaImages, t } = useBlog();

  return (
    <div className="container">
      <div className={styles.wrapper}>
        <div className={styles.date}>
          <h2>19.03.2021.</h2>
        </div>
        <h1>{t('blog1.1.1')}</h1>
        <h2>{t('blog1.1.2')}</h2>
        <CarouselBlog data={corvetteImages} />
        <p>{t('blog1.2.1')}</p>
        <div className={styles.section3}>
          <p className={styles.bold}>{t('blog1.3.1')}</p>
          <p>{t('blog1.3.2')}</p>
        </div>
        {t('blog1.4.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.4.1')}</h3>
            <ul>
              <li>{t('blog1.4.2')}</li>
              <li>{t('blog1.4.3')}</li>
              <li>{t('blog1.4.4')}</li>
              <li>{t('blog1.4.5')}</li>
              <li>{t('blog1.4.6')}</li>
            </ul>
          </div>
        )}
        <div className={styles.section3}>
          <p className={styles.bold}>{t('blog1.5.1')}</p>
          <p>{t('blog1.5.2')}</p>
        </div>
        <p>{t('blog1.6.1')}</p>
        <p>{t('blog1.7.1')}</p>
        {t('blog1.8.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.8.1')}</h3>
            <ul>
              <li>{t('blog1.8.2')}</li>
              <li>{t('blog1.8.3')}</li>
              <li>{t('blog1.8.4')}</li>
              <li>{t('blog1.8.5')}</li>
              <li>{t('blog1.8.6')}</li>
              <li>{t('blog1.8.7')}</li>
              <li>{t('blog1.8.8')}</li>
            </ul>
          </div>
        )}
        <p>{t('blog1.9.1')}</p>
        {t('blog1.10.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.10.1')}</h3>
            <ul>
              <li>{t('blog1.10.2')}</li>
              <li>{t('blog1.10.3')}</li>
              <li>{t('blog1.10.4')}</li>
            </ul>
          </div>
        )}
        {t('blog1.11.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.11.1')}</h3>
            <ul>
              <li>{t('blog1.11.2')}</li>
              <li>{t('blog1.11.3')}</li>
              <li>{t('blog1.11.4')}</li>
              <li>{t('blog1.11.5')}</li>
              <li>{t('blog1.11.6')}</li>
            </ul>
          </div>
        )}
        {t('blog1.12.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.12.1')}</h3>
            <ul>
              <li>{t('blog1.12.2')}</li>
              <li>{t('blog1.12.3')}</li>
              <li>{t('blog1.12.4')}</li>
              <li>{t('blog1.12.5')}</li>
            </ul>
          </div>
        )}
        {t('blog1.13.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.13.1')}</h3>
            <ul>
              <li>{t('blog1.13.2')}</li>
              <li>{t('blog1.13.3')}</li>
              <li>{t('blog1.13.4')}</li>
            </ul>
          </div>
        )}
        <div className={styles.section3}>
          <p className={styles.bold}>{t('blog1.14.1')}</p>
          <p>{t('blog1.14.2')}</p>
        </div>
        <p>{t('blog1.15.1')}</p>
        {t('blog1.16.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.16.1')}</h3>
            <ul>
              <li>{t('blog1.16.2')}</li>
              <li>{t('blog1.16.3')}</li>
              <li>{t('blog1.16.4')}</li>
              <li>{t('blog1.16.5')}</li>
              <li>{t('blog1.16.6')}</li>
              <li>{t('blog1.16.7')}</li>
              <li>{t('blog1.16.8')}</li>
            </ul>
          </div>
        )}
        {t('blog1.17.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.17.1')}</h3>
            <ul>
              <li>{t('blog1.17.2')}</li>
              <li>{t('blog1.17.3')}</li>
              <li>{t('blog1.17.4')}</li>
            </ul>
          </div>
        )}
        <div className={styles.section3}>
          <p className={styles.bold}>{t('blog1.18.1')}</p>
          <p>{t('blog1.18.2')}</p>
        </div>
        <p>{t('blog1.19.1')}</p>
        <p>{t('blog1.20.1')}</p>
        <p>{t('blog1.21.1')}</p>
        {t('blog1.22.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.22.1')}</h3>
            <ul>
              <li>{t('blog1.22.2')}</li>
              <li>{t('blog1.22.3')}</li>
              <li>{t('blog1.22.4')}</li>
              <li>{t('blog1.22.5')}</li>
              <li>{t('blog1.22.6')}</li>
              <li>
                {t('blog1.22.7')}
                <ul>
                  <li>{t('blog1.22.7.1')}</li>
                  <li>{t('blog1.22.7.2')}</li>
                  <li>{t('blog1.22.7.3')}</li>
                  <li>{t('blog1.22.7.4')}</li>
                  <li>{t('blog1.22.7.5')}</li>
                  <li>{t('blog1.22.7.6')}</li>
                </ul>
              </li>
              <li>{t('blog1.22.7')}</li>
              <li>{t('blog1.22.8')}</li>
              <li>{t('blog1.22.9')}</li>
              <li>{t('blog1.22.10')}</li>
            </ul>
          </div>
        )}
        <div className={styles.section3}>
          <p className={styles.bold}>{t('blog1.23.1')}</p>
          <p>{t('blog1.23.2')}</p>
        </div>
        <p>{t('blog1.24.1')}</p>
        <p>{t('blog1.25.1')}</p>
        <p>{t('blog1.26.1')}</p>
        <p>{t('blog1.27.1')}</p>
        {t('blog1.18.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.28.1')}</h3>
            <ul>
              <li>{t('blog1.28.2')}</li>
              <li>{t('blog1.28.3')}</li>
              <li>{t('blog1.28.4')}</li>
              <li>{t('blog1.28.5')}</li>
              <li>{t('blog1.28.6')}</li>
              <li>{t('blog1.28.7')}</li>
            </ul>
          </div>
        )}
        <div className={styles.section3}>
          <p className={styles.bold}>{t('blog1.29.1')}</p>
          <p>{t('blog1.29.2')}</p>
        </div>
        <p>{t('blog1.30.1')}</p>
        <p>{t('blog1.31.1')}</p>
        <p>{t('blog1.32.1')}</p>
        <div className={styles.section3}>
          <p className={styles.bold}>{t('blog1.33.1')}</p>
          <p>{t('blog1.33.2')}</p>
        </div>
        {t('blog1.33.1') && (
          <div className={styles.content}>
            <ul>
              <li>{t('blog1.33.3')}</li>
              <li>{t('blog1.33.2')}</li>
            </ul>
          </div>
        )}
        <p>{t('blog1.34.1')}</p>
        {t('blog1.35.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.35.1')}</h3>
            <ul>
              <li>{t('blog1.35.2')}</li>
              <li>{t('blog1.35.3')}</li>
              <li>{t('blog1.35.4')}</li>
              <li>{t('blog1.35.5')}</li>
              <li>{t('blog1.35.6')}</li>
              <li>{t('blog1.35.7')}</li>
            </ul>
          </div>
        )}
        {t('blog1.36.1') && (
          <div className={styles.content}>
            <h3>{t('blog1.36.1')}</h3>
            <ul>
              <li>{t('blog1.36.2')}</li>
              <li>{t('blog1.36.3')}</li>
              <li>{t('blog1.36.4')}</li>
              <li>{t('blog1.36.5')}</li>
              <li>{t('blog1.36.6')}</li>
            </ul>
          </div>
        )}
        <div className={styles.section3}>
          <p className={styles.bold}>{t('blog1.37.1')}</p>
          <p>{t('blog1.37.2')}</p>
        </div>
        <p>{t('blog1.38.1')}</p>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.date}>
          <h2>19.03.2021.</h2>
        </div>
        <h1>{t('blog2.1.1')}</h1>
        <h2>{t('blog2.1.2')}</h2>
        <CarouselBlog data={toyotaImages} />
        <p>{t('blog2.2.1')}</p>
        <p>{t('blog2.3.1')}</p>
        <p>{t('blog2.4.1')}</p>
        <p>{t('blog2.5.1')}</p>
        <p>{t('blog2.6.1')}</p>
        <p>{t('blog2.7.1')}</p>
        <p>{t('blog2.8.1')}</p>
      </div>
    </div>
  );
};

export default Blog;
