import React from 'react';
import { IInput } from '../../../types';

import s from './Input.module.scss';

const Input = ({
  inputClass,
  type,
  name,
  value,
  placeholder,
  onChange,
  label,
  disabled = false,
  autofocus = false,
  error,
}: IInput) => {
  return (
    <div className={s['FormGroup']}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        id={name}
        className={s[inputClass]}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autofocus}
        disabled={disabled}
      />
      {error && (
        <div className="input-err-msg">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default Input;
