import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { mapDataToString } from '../../../utils';
import { dataActions } from '../../../store/actions/data.actions';
import { EFilter, IFilters, ISelect, IState } from '../../../types';
import { STEP_2_TYPES } from '../../../constants';

const useStep2 = () => {
  const { t } = useTranslation();
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const dataStore = useSelector((state: IState) => state.data);
  const [models, setModels] = useState<ISelect[]>([]);
  const [brands, setBrands] = useState<ISelect[]>([]);

  useEffect(() => {
    fetchBrands().then();

    if (dataStore.selectedFilters.brand) {
      dataStore.selectedFilters.brand.map((brand: string) => {
        dispatch(dataActions.getModels(brand) as unknown as AnyAction);
      });
    }
  }, []);

  useEffect(() => {
    if (dataStore.brands) {
      setBrands(
        dataStore.brands?.map((item: { label: string; value: string }) => {
          return { label: item, value: item };
        })
      );
    }
    if (dataStore.models) {
      mapModels(dataStore.models).then();
    }
  }, [dataStore.brands, dataStore.models]);

  const fetchBrands = async () => {
    dispatch(dataActions.getCarsBrands() as unknown as AnyAction);
  };

  const handleRange = (value: { priceFrom: number; priceTo: number }) => {
    storeFilter({ ...value });
  };

  const mapMultiSelectResponse = (selected: ISelect[], type: string) => {
    const data = selected ? selected.map((e: ISelect) => e.value) : [];
    if (data.length) {
      storeFilter({ [type]: data });
    } else {
      const filter = dataStore.selectedFilters;
      delete filter[type];
      storeFilter(filter);
    }
  };

  const handleSelectChange = async (selected: ISelect[], type: string) => {
    mapMultiSelectResponse(selected, type);
    if (type === 'brand') {
      if (selected.length) {
        dispatch(
          dataActions.getModels(
            mapDataToString(selected)
          ) as unknown as AnyAction
        );
      } else {
        const filters = dataStore.selectedFilters;
        delete filters[EFilter.brand];
        delete filters[EFilter.model];
        storeFilter(filters);
      }
    }
  };

  const mapModels = async (results: string[]) => {
    let elements: { label: string; value: string }[] = [];
    let items: { label: string; value: string }[] = [];
    let seriesLabel = '';
    let seriesValue: string[] = [];

    for (let i = 0; i <= results.length; i++) {
      if (results[i]?.startsWith('   ')) {
        const el = results[i].replace('   ', '');
        items.push({ label: el, value: el });
        seriesValue.push(el);
      } else {
        i !== 0 &&
          elements.push({
            label: seriesLabel,
            value: seriesValue.length ? seriesValue.toString() : seriesLabel,
          });
        elements = [...elements, ...items];
        seriesLabel = results[i];
        items = [];
        seriesValue = [];
      }
    }

    setModels(elements);
  };

  const getSelectedValue = (options: ISelect[], type: string) => {
    const selectedData: ISelect[] = [];
    dataStore.selectedFilters[type]?.map((item: string) => {
      const element = options.find((option) => option.value === item);
      if (element !== undefined) {
        if (type === EFilter.fuel) {
          selectedData.push({
            label: i18n.t(element?.label),
            value: element.value,
          });
        } else {
          selectedData.push(element);
        }
      }
    });
    return selectedData;
  };

  const storeFilter = (data: IFilters) => {
    dispatch(dataActions.selectedFilters(data) as unknown as AnyAction);
  };

  const resetFilter = async () => {
    const filters = dataStore.selectedFilters;
    STEP_2_TYPES.map((item) => {
      delete filters[item];
    });
    storeFilter(filters);
  };

  return {
    filters: dataStore.selectedFilters,
    brands,
    handleRange,
    handleSelectChange,
    getSelectedValue,
    models,
    resetFilter,
    t,
  };
};

export default useStep2;
