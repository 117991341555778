import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, numberWithCommas } from '../../utils';
import { IVehicle } from '../../types';

import s from './SinglePost.module.scss';

const SinglePostTechData = ({ data }: { data: IVehicle }) => {
  const { t } = useTranslation();

  const chech = (value: number | string) => {
    return value !== undefined && value !== null;
  };

  return (
    <div className={s['TechData']}>
      <h1>{t('single.post.technical.data')}</h1>
      {chech(data.price) && (
        <div className={s['Text']}>
          <p>{t('single.post.price')}</p>
          <p>{formatPrice(data.price)}</p>
        </div>
      )}
      {chech(data.bodyType) && (
        <div className={s['Text']}>
          <p>{t('single.post.category')}</p>
          <p>{data.bodyType}</p>
        </div>
      )}
      {chech(data.vehicleNum) && (
        <div className={s['Text']}>
          <p>{t('single.post.vehicle.num')}</p>
          <p>{data.vehicleNum}</p>
        </div>
      )}
      {chech(data.mileage) && (
        <div className={s['Text']}>
          <p>{t('single.post.mileage')}</p>
          <p>{numberWithCommas(data.mileage)}</p>
        </div>
      )}
      {chech(data.cubicCapacity) && (
        <div className={s['Text']}>
          <p>{t('single.post.cubic.capacity')}</p>
          <p>{numberWithCommas(data.cubicCapacity)}</p>
        </div>
      )}
      {chech(data.horsePower) && (
        <div className={s['Text']}>
          <p>{t('single.post.power')}</p>
          <p>{data.horsePower}</p>
        </div>
      )}
      {chech(data.fuel) && (
        <div className={s['Text']}>
          <p>{t('single.post.fuel')}</p>
          <p>{data.fuel}</p>
        </div>
      )}
      {chech(data.fuelConsumption) && (
        <div className={s['Text']}>
          <p>{t('single.post.fuel.consumption')}</p>
          <p>{data.fuelConsumption}</p>
        </div>
      )}
      {chech(data.co2Emissions) && (
        <div className={s['Text']}>
          <p>{t('single.post.emissions')}</p>
          <p>{data.co2Emissions}</p>
        </div>
      )}
      {chech(data.seats) && (
        <div className={s['Text']}>
          <p>{t('single.post.seat.number')}</p>
          <p>{data.seats}</p>
        </div>
      )}
      {chech(data.doorCount) && (
        <div className={s['Text']}>
          <p>{t('single.post.door.number')}</p>
          <p>{data.doorCount}</p>
        </div>
      )}
      {chech(data.gearbox) && (
        <div className={s['Text']}>
          <p>{t('single.post.gearbox')}</p>
          <p>{data.gearbox}</p>
        </div>
      )}
      {chech(data.emissionClass) && (
        <div className={s['Text']}>
          <p>{t('single.post.emission.class')}</p>
          <p>{data.emissionClass}</p>
        </div>
      )}
      {chech(data.emissionsSticker) && (
        <div className={s['Text']}>
          <p>{t('single.post.emission.sticker')}</p>
          <p>{data.emissionsSticker}</p>
        </div>
      )}
      {chech(data.firstRegistrationYear) && (
        <div className={s['Text']}>
          <p>{t('label.registration')}</p>
          <p>{data.firstRegistrationYear}</p>
        </div>
      )}
      {chech(data.ownerNumber) && (
        <div className={s['Text']}>
          <p>{t('single.post.owner.number')}</p>
          <p>{data.ownerNumber}</p>
        </div>
      )}
      {chech(data.hu) && (
        <div className={s['Text']}>
          <p>{t('single.post.hu')}</p>
          <p>{data.hu}</p>
        </div>
      )}
      {chech(data.climatisation) && (
        <div className={s['Text']}>
          <p>{t('single.post.climatisation')}</p>
          <p>{data.climatisation}</p>
        </div>
      )}
      {chech(data.parkingSensors) && (
        <div className={s['Text']}>
          <p>{t('single.post.parking.sensors')}</p>
          <p>{data.parkingSensors}</p>
        </div>
      )}
      {chech(data.airbag) && (
        <div className={s['Text']}>
          <p>{t('single.post.airbag')}</p>
          <p>{data.airbag}</p>
        </div>
      )}
      {chech(data.bodyColor) && (
        <div className={s['Text']}>
          <p>{t('single.post.color')}</p>
          <p>{data.bodyColor}</p>
        </div>
      )}
      {chech(data.interiorDesign) && (
        <div className={s['Text']}>
          <p>{t('single.post.interior')}</p>
          <p>{data.interiorDesign}</p>
        </div>
      )}
    </div>
  );
};

export default SinglePostTechData;
