import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPostalCodes, IState, IStore } from '../../../types';
import postalCodes from '../../../utils/zipCodesCH.json';
import { dataActions } from '../../../store/actions/data.actions';
import { AnyAction } from 'redux';

const useAutocomplete = () => {
  const dispatch = useDispatch();
  const dataStore: IStore = useSelector((state: IState) => state.data);
  const [inputValue, setInputValue] = useState(
    dataStore.selectedFilters.location || ''
  );
  const [suggestions, setSuggestions] = useState<IPostalCodes[]>([]);

  useEffect(() => {
    if (
      dataStore.selectedFilters.location === undefined &&
      inputValue.length > 0
    ) {
      setInputValue('');
    }
  }, [dataStore.selectedFilters.location]);

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleInputBlur = () => {
    setSuggestions([]);
    if (dataStore.selectedFilters.location === undefined) {
      setInputValue('');
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(e.target.value);
    const results: IPostalCodes[] = [];
    if (value.length > 2) {
      postalCodes.map((item: IPostalCodes) => {
        if (String(item.zip).includes(value) || item.place.includes(value)) {
          results.push({ ...item, text: `${item.zip} ${item.place}` });
        }
      });
    }
    setSuggestions(results);
  };

  const handleResultItemClick = (e: MouseEvent<HTMLLIElement>) => {
    const id = e.currentTarget.getAttribute('data-id');
    const value = suggestions.find((item) => item.id === id);
    if (value === undefined || value.text === undefined) return;
    setInputValue(value.text);
    new Promise((resolve) =>
      resolve(
        dispatch(
          dataActions.selectedFilters({
            latitude: value.latitude,
            longitude: value.longitude,
            location: value.text,
          }) as unknown as AnyAction
        )
      )
    ).then(() => {
      setSuggestions([]);
    });
  };

  const handleInputReset = () => {
    const filters = dataStore.selectedFilters;
    setInputValue('');
    setSuggestions([]);
    delete filters['latitude'];
    delete filters['longitude'];
    delete filters['location'];
    delete filters['radius'];
    dispatch(dataActions.selectedFilters(filters) as unknown as AnyAction);
  };

  return {
    handleInputBlur,
    handleInputChange,
    handleInputReset,
    handleMouseDown,
    handleResultItemClick,
    inputValue,
    suggestions,
  };
};

export default useAutocomplete;
