import Keycloak from 'keycloak-js';
import { keycloakUrl, keycloakRealm, keycloakClientId } from './environment';

const keycloak = new Keycloak({
  url: keycloakUrl,
  realm: keycloakRealm,
  clientId: keycloakClientId,
});

export default keycloak;
