import React from 'react';
import useMark from './useMark';
import Button from '../UI/Button';
import {
  StarFilledIcon,
  StarMarkIcon,
  StarUnmarkIcon,
} from '../../assets/images';
import { IVehicle } from '../../types';

const Mark = ({ type, item }: { type: string; item: IVehicle }) => {
  const { handleMark, handleUnmark, marked, t } = useMark(item);

  return (
    <>
      {type === 'icon' && (
        <img
          alt={''}
          src={marked ? StarFilledIcon : StarMarkIcon}
          onClick={marked ? handleUnmark : handleMark}
        />
      )}
      {type === 'button' && (
        <Button
          btnClass={marked ? 'btnMark' : 'btnPrimary'}
          label={marked ? t('btn.unmark') : t('btn.mark')}
          onClick={marked ? handleUnmark : handleMark}
          iconLeft={marked ? StarMarkIcon : StarUnmarkIcon}
        />
      )}
    </>
  );
};

export default Mark;
