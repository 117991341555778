export enum EFilter {
  bodyColor = 'bodyColor',
  bodyType = 'bodyType',
  brand = 'brand',
  city = 'city',
  country = 'country',
  cubicCapacity = 'cubicCapacity',
  radius = 'radius',
  fuel = 'fuel',
  gearbox = 'gearbox',
  location = 'location',
  latitude = 'latitude',
  longitude = 'longitude',
  mileage = 'mileage',
  model = 'model',
  price = 'price',
  firstRegistrationYear = 'firstRegistrationYear',
  horsePower = 'horsePower',
  entryDate = 'entryDate',
  seats = 'seats',
  driveType = 'driveType',
}

export enum ERange {
  from = 'From',
  to = 'To',
}
