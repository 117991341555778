import React, { Fragment } from 'react';
import ReactLoading from 'react-loading';
import useResults from './useResults';
import Pagination from '../../components/UI/Pagination';
import SortBy from '../../components/UI/SortBy/SortBy';
import GoogleAd from '../../components/Ad';
import Card from '../../components/Card';
import ActiveFilter from '../../components/ActiveFilter';
import { getId } from '../../utils';
import { ECardType, ERouterPath, IVehicle } from '../../types';

const Results = () => {
  const { dataStore, loading, t } = useResults();

  return (
    <div className="container">
      {loading ? (
        <ReactLoading
          type={'spokes'}
          color={'#FFA217'}
          height={60}
          width={60}
          className="loader"
        />
      ) : dataStore?.list?.length > 0 ? (
        <div>
          {!location.search.startsWith('?search') ? <ActiveFilter /> : null}
          <SortBy pathname={ERouterPath.Results} />

          {dataStore.list.map((item: IVehicle, index: number) => {
            return (
              <Fragment key={getId(item._links.self.href)}>
                <Card
                  item={item}
                  key={getId(item._links.self.href)}
                  cardType={ECardType.cardPrimary}
                />
                {(index + 1) % 4 === 0 && (
                  <div className="ads" style={{ marginBottom: '16px' }}>
                    <section>
                      <GoogleAd />
                    </section>
                  </div>
                )}
              </Fragment>
            );
          })}

          <Pagination
            defaultPageSize={dataStore.pagination.size}
            pathname={ERouterPath.Results}
            total={
              dataStore.pagination.totalElements
                ? dataStore.pagination.totalElements
                : dataStore.list.length
            }
          />
        </div>
      ) : (
        <h1>{t('no.results')}</h1>
      )}
    </div>
  );
};

export default Results;
