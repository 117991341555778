import React from 'react';
import ReactLoading from 'react-loading';
import useSaveNotification from './useSaveNotification';
import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';
import { FILTERS_LABEL_MAP, reverseNotificationData } from '../../utils';
import { EReverse } from '../../types';

import s from './SaveNotification.module.scss';

const SaveNotification = () => {
  const {
    data,
    error,
    goBack,
    handleInputChange,
    handleRemoveSearchItem,
    handleSaveNotification,
    loading,
    t,
    translate,
  } = useSaveNotification();

  return (
    <div className="container">
      {loading ? (
        <ReactLoading
          type={'spokes'}
          color={'#FFA217'}
          height={60}
          width={60}
          className="loader"
        />
      ) : (
        <div className={s['Container']}>
          <div className={s['Content']}>
            <h2>{t('notification.id')}</h2>
            <Input
              inputClass="InputPrimary"
              type="text"
              name="name"
              value={data.name}
              onChange={handleInputChange}
              placeholder={t('notification.placeholder')}
            />
            <p className={s['Error']}>
              {error ? `* ${t('errMsg.allFields')}` : ' '}
            </p>

            <h4>{t('save.notification.title')}:</h4>
            <div className={s['Wrapper']}>
              {Object.keys(data).map((key, index) => (
                <div className={s['Text']} key={index}>
                  {FILTERS_LABEL_MAP[key as EReverse] && (
                    <>
                      {key !== EReverse.name && (
                        <>
                          {key === EReverse.brand || key === EReverse.model ? (
                            <Button
                              btnClass="filterBtn"
                              label={`${t(
                                FILTERS_LABEL_MAP[key as EReverse]
                              )}:  ${
                                data[key].length > 1
                                  ? data[key]?.join(', ')
                                  : data[key]?.toString().split(',').join(', ')
                              }`}
                              iconRight="+"
                              onIconRightClick={() =>
                                handleRemoveSearchItem(key)
                              }
                            />
                          ) : (
                            <Button
                              btnClass="filterBtn"
                              label={`${t(
                                FILTERS_LABEL_MAP[key as EReverse]
                              )}: ${
                                Array.isArray(data[key])
                                  ? translate(
                                      reverseNotificationData(
                                        data[key],
                                        key
                                      ) as string[],
                                      key
                                    )
                                  : t(data[key])
                              }`}
                              iconRight="+"
                              onIconRightClick={() =>
                                handleRemoveSearchItem(key as EReverse)
                              }
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className={s['Options']}>
              <Button
                btnClass="btnPrimary"
                onClick={handleSaveNotification}
                label={t('btn.save.notification')}
              />
              <Button
                btnClass="btnSecondary"
                label={t('btn.back')}
                onClick={goBack}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SaveNotification;
