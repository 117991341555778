export enum ERouterPath {
  Home = '/',
  Blog = '/blog',
  Results = '/results',
  Login = '/login',

  PrivacyPolicy = '/privacy-policy',

  TermsAndConditions = '/terms-and-conditions',

  Notifications = '/notifications',
  Profile = '/profile',
  Favourites = '/favourites',
  SaveNotification = '/save-notification',

  SinglePost = '/ad/:id',

  NoMatch = '*',
}
