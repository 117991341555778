import { ELanguage, ISelect } from '../types';

export const PRICE = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 125, 150, 175,
  200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000,
];

export const MILEAGE = [
  25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750,
  2000,
];

export const CUBIC_CAPACITY = [10, 12, 14, 16, 18, 20, 26, 30, 50, 75, 90];

export const HORSE_POWER = [
  34, 50, 60, 75, 90, 101, 118, 131, 150, 200, 252, 303, 358, 402, 454,
];

export const COUNTRIES: ISelect[] = [
  {
    id: 'CH',
    value: 'CH',
    label: 'Switzerland',
  },
];

export const DISTANCE: ISelect[] = [
  {
    label: '10 km',
    value: '10',
  },
  {
    label: '20 km',
    value: '20',
  },
  {
    label: '50 km',
    value: '50',
  },
  {
    label: '100 km',
    value: '100',
  },
  {
    label: '150 km',
    value: '150',
  },
  {
    label: '200 km',
    value: '2000',
  },
  {
    label: '250 km',
    value: '250',
  },
  {
    label: '300 km',
    value: '300',
  },
  {
    label: '400 km',
    value: '400',
  },
];

export const DRIVE_TYPE = [
  {
    id: '',
    name: 'driveType',
    title: 'drive1',
  },
  {
    id: '1',
    name: 'driveType',
    title: 'drive2',
  },
  {
    id: '2',
    name: 'driveType',
    title: 'drive3',
  },
  {
    id: '3',
    name: 'driveType',
    title: 'drive4',
  },
];

export const GEARBOX = [
  {
    id: '',
    name: 'gearbox',
    title: 'gearbox1',
  },
  {
    id: '1',
    name: 'gearbox',
    title: 'gearbox2',
  },
  {
    id: '2',
    name: 'gearbox',
    title: 'gearbox3',
  },
];

export const ENTRY_DATE = [
  {
    id: '1',
    name: 'entryDate',
    title: 'entryDate1',
  },
  {
    id: '2',
    name: 'entryDate',
    title: 'entryDate2',
  },
  {
    id: '3',
    name: 'entryDate',
    title: 'entryDate3',
  },
  {
    id: '4',
    name: 'entryDate',
    title: 'entryDate4',
  },
  {
    id: '5',
    name: 'entryDate',
    title: 'entryDate5',
  },
];

export const LANGUAGES = [
  {
    label: ELanguage.En.toUpperCase(),
    value: ELanguage.En.toUpperCase(),
  },
  {
    label: ELanguage.De.toUpperCase(),
    value: ELanguage.De.toUpperCase(),
  },
  {
    label: ELanguage.It.toUpperCase(),
    value: ELanguage.It.toUpperCase(),
  },
  {
    label: ELanguage.Fr.toUpperCase(),
    value: ELanguage.Fr.toUpperCase(),
  },
];
