import httpService from './http.service';
import { EMethod, IKey } from '../types';

class DataService {
  async getFavourites(query: string) {
    return httpService.apiRequest(
      EMethod.GET,
      `/search/favorites/user/${query}`
    );
  }
  async mark(adId: string, originalId: string) {
    return httpService.apiRequest(EMethod.POST, '/search/favorites', {
      adId,
      originalId,
    });
  }

  async unmark(adId: string) {
    return httpService.apiRequest(EMethod.DELETE, `/search/favorites/${adId}`);
  }

  async getSinglePost(id: string) {
    return httpService.apiRequest(EMethod.GET, `/search/allcars/${id}`);
  }

  async getCarsBrandsApi() {
    return httpService.apiRequest(EMethod.GET, '/search/allcars/brands');
  }

  async getModelsApi(brand: string) {
    return httpService.apiRequest(
      EMethod.GET,
      `/search/allcars/models?brand=${brand}`
    );
  }

  async searchByFilterQuery(query: string) {
    return httpService.apiRequest(EMethod.GET, `/search/allcars/${query}`);
  }

  async getAllCarsCountApi(query: string) {
    return httpService.apiRequest(
      EMethod.GET,
      `/search/allcars/count/?${query}`
    );
  }

  async getAllNotificationsApi() {
    return httpService.apiRequest(
      EMethod.GET,
      '/notifications/notifications/user'
    );
  }

  async deleteNotification(adId: string) {
    return httpService.apiRequest(
      EMethod.DELETE,
      `/notifications/notifications/${adId}`
    );
  }

  async addNotification(data: IKey) {
    return httpService.apiRequest(
      EMethod.POST,
      '/notifications/notifications',
      data
    );
  }

  async getUserFavorites() {
    return httpService.apiRequest(EMethod.GET, '/search/favorites/user/ads');
  }
}

export default new DataService();
