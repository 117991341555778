import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => {
  const [t] = useTranslation();
  return (
    <div className="container">
      <div className={styles.wrapper}>
        <h1>{t('privacy.header')}</h1>

        <div className={styles.header}>
          <p>{t('privacy.1.1')}</p>
          <p>{t('privacy.1.2')}</p>
          <p>{t('privacy.1.3')}</p>
          <p>{t('privacy.1.4')}</p>
          <p>{t('privacy.1.5')}</p>
          <p>{t('privacy.1.6')}</p>
          <p>{t('privacy.1.7')}</p>
          <p>{t('privacy.1.8')}</p>
          <p>{t('privacy.1.9')}</p>
          <p>{t('privacy.1.10')}</p>
        </div>

        <div className={styles.content}>
          <h3>{t('privacy.2.1')}</h3>
          <ul>
            <li>{t('privacy.2.2')}</li>
            <li>{t('privacy.2.3')}</li>
            <li>{t('privacy.2.4')}</li>
            <li>{t('privacy.2.5')}</li>
            <li>{t('privacy.2.6')}</li>
            <li>{t('privacy.2.7')}</li>
            <li>{t('privacy.2.8')}</li>
            <li>{t('privacy.2.9')}</li>
          </ul>
        </div>

        <div className={styles.content}>
          <h3>{t('privacy.3.1')}</h3>
          <ul>
            <li>{t('privacy.3.2')}</li>
            <li>{t('privacy.3.3')}</li>
            <li>{t('privacy.3.4')}</li>
            <li>{t('privacy.3.5')}</li>
            <li>{t('privacy.3.6')}</li>
          </ul>
        </div>

        <div className={styles.content}>
          <h3>{t('privacy.4.1')}</h3>
          <ul>
            <li>{t('privacy.4.2')}</li>
            <li>{t('privacy.4.4')}</li>
            <li>{t('privacy.4.4')}</li>
            <li>{t('privacy.4.5')}</li>
            <li>{t('privacy.4.6')}</li>

            <li>{t('privacy.4.7')}</li>
            <li>{t('privacy.4.8')}</li>
            <li>{t('privacy.4.9')}</li>
            <li>{t('privacy.4.10')}</li>
            <li>{t('privacy.4.11')}</li>
            <li>{t('privacy.4.12')}</li>
            <li>{t('privacy.4.13')}</li>
            <li>{t('privacy.4.14')}</li>
            <li>{t('privacy.4.15')}</li>
            <li>{t('privacy.4.16')}</li>
          </ul>
        </div>

        <div className={styles.section}>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.5.1')}</span>
            <span> {t('privacy.5.2')}</span>
          </div>
          <p>{t('privacy.5.3')}</p>
          <p>{t('privacy.5.4')}</p>
          <p>{t('privacy.5.5')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.5.6')}</span>
            <span> {t('privacy.5.7')}</span>
          </div>
          <p>{t('privacy.5.8')}</p>
          <p>{t('privacy.5.9')}</p>

          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.5.10')}</span>
            <span> {t('privacy.5.11')}</span>
          </div>
          <p>{t('privacy.5.12')}</p>
          <p>{t('privacy.5.13')}</p>
          <p>{t('privacy.5.14')}</p>
          <p>{t('privacy.5.15')}</p>
          <p>{t('privacy.5.16')}</p>
          <p>{t('privacy.5.17')}</p>
        </div>

        <div className={styles.section2}>
          <h3 className={styles.bold}>{t('privacy.6.1')}</h3>
          <ul>
            <li>
              <span className={styles.bold}>{t('privacy.6.2')}</span>
              <span> {t('privacy.6.3')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.6.4')}</span>
              <span> {t('privacy.6.5')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.6.6')}</span>
              <span> {t('privacy.6.7')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.6.8')}</span>
              <span> {t('privacy.6.9')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.6.10')}</span>
              <span> {t('privacy.6.11')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.6.12')}</span>
              <span> {t('privacy.6.13')}</span>
            </li>
          </ul>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.6.14')}</span>
            <span> {t('privacy.6.15')}</span>
          </div>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.6.16')}</span>
            <span> {t('privacy.6.17')}</span>
          </div>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.6.18')}</span>
            <span> {t('privacy.6.19')}</span>
          </div>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.6.20')}</span>
            <span> {t('privacy.6.21')}</span>
          </div>
          <p> {t('privacy.6.22')}</p>
          <p> {t('privacy.6.23')}</p>
          <p> {t('privacy.6.24')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.6.25')}</span>
            <span> {t('privacy.6.26')}</span>
          </div>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.6.27')}</span>
            <span> {t('privacy.6.28')}</span>
          </div>
          <p>{t('privacy.6.29')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.6.30')}</span>
            <span> {t('privacy.6.31')}</span>
          </div>
          <p>{t('privacy.6.32')}</p>
          <p>{t('privacy.6.33')}</p>
          <p>{t('privacy.6.34')}</p>
          <p>{t('privacy.6.35')}</p>
        </div>

        <div className={styles.section2}>
          <p>{t('privacy.7.1')}</p>
          <p>{t('privacy.7.2')}</p>
          <p>{t('privacy.7.3')}</p>
          <p>{t('privacy.7.4')}</p>
          <p>{t('privacy.7.5')}</p>
          <p>{t('privacy.7.6')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.7.7')}</span>
            <span> {t('privacy.7.8')}</span>
          </div>
        </div>

        <div className={styles.section2}>
          <h3 className={styles.bold}>{t('privacy.8.1')}</h3>
          <ul>
            <li>
              <span className={styles.bold}>{t('privacy.8.2')}</span>
              <span> {t('privacy.8.3')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.8.4')}</span>
              <span> {t('privacy.8.5')}</span>
            </li>
          </ul>
          <p>{t('privacy.8.6')}</p>
          <p>{t('privacy.8.7')}</p>
          <p>{t('privacy.8.8')}</p>
          <p>{t('privacy.8.9')}</p>
          <p>{t('privacy.8.10')}</p>
          <p>{t('privacy.8.11')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.8.12')}</span>
            <span> {t('privacy.8.13')}</span>
          </div>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.8.14')}</span>
            <span> {t('privacy.8.15')}</span>
          </div>
          <p>{t('privacy.8.16')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.8.17')}</span>
            <span> {t('privacy.8.18')}</span>
          </div>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.8.19')}</span>
            <span> {t('privacy.8.20')}</span>
          </div>
          <p>{t('privacy.8.21')}</p>
          <p>{t('privacy.8.22')}</p>
          <p>{t('privacy.8.23')}</p>
          <p>{t('privacy.8.24')}</p>
          <p>{t('privacy.8.25')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.8.26')}</span>
            <span> {t('privacy.8.27')}</span>
          </div>

          <h3 className={styles.bold}>{t('privacy.8.28')}</h3>
          <ul>
            <li>
              <span className={styles.bold}>{t('privacy.8.29')}</span>
              <span> {t('privacy.8.30')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.8.31')}</span>
              <span> {t('privacy.8.32')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.8.33')}</span>
              <span> {t('privacy.8.34')}</span>
            </li>
          </ul>
          <p>{t('privacy.9.1')}</p>
          <p>{t('privacy.9.2')}</p>
          <p>{t('privacy.9.3')}</p>
          <p>{t('privacy.9.4')}</p>
          <p>{t('privacy.9.5')}</p>
          <p>{t('privacy.9.6')}</p>
          <p>{t('privacy.9.7')}</p>
          <p>{t('privacy.9.8')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.9.9')}</span>
            <span> {t('privacy.9.10')}</span>
          </div>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.9.11')}</span>
            <span> {t('privacy.9.12')}</span>
          </div>
          <ul>
            <li>
              <span className={styles.bold}>{t('privacy.9.13')}</span>
              <span> {t('privacy.9.14')}</span>
            </li>
          </ul>
          <h3 className={styles.bold}>{t('privacy.9.15')}</h3>

          <ul>
            <li>
              <span className={styles.bold}>{t('privacy.9.16')}</span>
              <span> {t('privacy.9.17')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.9.18')}</span>
              <span> {t('privacy.9.19')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.9.20')}</span>
              <span> {t('privacy.9.21')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.9.22')}</span>
              <span> {t('privacy.9.23')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.9.24')}</span>
              <span> {t('privacy.9.25')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.9.26')}</span>
              <span> {t('privacy.9.27')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.9.28')}</span>
              <span> {t('privacy.9.29')}</span>
            </li>
          </ul>
          <p>{t('privacy.10.1')}</p>
          <p>{t('privacy.10.2')}</p>
          <p>{t('privacy.10.3')}</p>
          <p>{t('privacy.10.4')}</p>
          <p>{t('privacy.10.5')}</p>
          <p>{t('privacy.10.6')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.10.7')}</span>
            <span> {t('privacy.10.8')}</span>
          </div>
          <h3 className={styles.bold}>{t('privacy.10.9')}</h3>
          <ul>
            <li>
              <span className={styles.bold}>{t('privacy.10.10')}</span>
              <span> {t('privacy.10.11')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.10.12')}</span>
              <span> {t('privacy.10.13')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.10.14')}</span>
              <span> {t('privacy.10.15')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.10.16')}</span>
              <span> {t('privacy.10.17')}</span>
            </li>
          </ul>
          <p>{t('privacy.11.1')}</p>
          <p>{t('privacy.11.2')}</p>
          <p>{t('privacy.11.3')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.11.4')}</span>
            <span> {t('privacy.11.5')}</span>
          </div>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.11.6')}</span>
            <span> {t('privacy.11.7')}</span>
          </div>
          <h3 className={styles.bold}>{t('privacy.11.8')}</h3>
          <ul>
            <li>
              <span className={styles.bold}>{t('privacy.11.9')}</span>
              <span> {t('privacy.11.10')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.11.11')}</span>
              <span> {t('privacy.11.12')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.11.13')}</span>
              <span> {t('privacy.11.14')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.11.15')}</span>
              <span> {t('privacy.11.16')}</span>
            </li>
          </ul>
          <p>{t('privacy.11.17')}</p>
          <p>{t('privacy.11.18')}</p>
          <p>{t('privacy.11.19')}</p>
          <p>{t('privacy.11.20')}</p>
          <div className={styles.combined}>
            <span className={styles.bold}>{t('privacy.12.1')}</span>
            <span> {t('privacy.12.2')}</span>
          </div>
          <p>{t('privacy.12.3')}</p>
          <p>{t('privacy.12.4')}</p>
          <p>{t('privacy.12.5')}</p>
          <p>{t('privacy.12.6')}</p>
          <p>{t('privacy.12.7')}</p>
          <p>{t('privacy.12.8')}</p>
          <p>{t('privacy.12.9')}</p>
          <p>{t('privacy.12.10')}</p>
          <p>{t('privacy.12.11')}</p>
          <ul>
            <li>
              <span className={styles.bold}>{t('privacy.12.12')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.12.13')}</span>
              <span> {t('privacy.12.14')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.12.15')}</span>
              <span> {t('privacy.12.16')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.12.17')}</span>
              <span> {t('privacy.12.18')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.12.19')}</span>
              <span> {t('privacy.12.20')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.12.21')}</span>
              <span> {t('privacy.12.22')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.12.23')}</span>
              <span> {t('privacy.12.24')}</span>
            </li>
          </ul>
          <p>{t('privacy.13.1')}</p>
          <p>{t('privacy.13.2')}</p>
          <ul>
            <li>
              <span className={styles.bold}>{t('privacy.13.3')}</span>
              <span> {t('privacy.13.4')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.5')}</span>
              <span> {t('privacy.13.6')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.7')}</span>
              <span> {t('privacy.13.8')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.9')}</span>
              <span> {t('privacy.13.10')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.11')}</span>
              <span> {t('privacy.13.12')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.13')}</span>
              <span> {t('privacy.13.14')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.15')}</span>
              <span> {t('privacy.13.16')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.17')}</span>
              <span> {t('privacy.13.18')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.19')}</span>
              <span> {t('privacy.13.20')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.21')}</span>
              <span> {t('privacy.13.22')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.23')}</span>
              <span> {t('privacy.13.24')}</span>
            </li>
            <li>
              <span className={styles.bold}>{t('privacy.13.25')}</span>
              <span> {t('privacy.13.26')}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
