import axios from 'axios';
import { localStorageService } from '../local/storage';
import { url } from '../environment';
import keycloak from '../keycloak';
import { EMethod, IKey } from '../types';
import { toast } from 'react-toastify';

axios.interceptors.response.use(
  (response) => {
    // Returns response body
    return response.data;
  },
  async (error) => {
    // Handle response error
    _handleError(error);
    return Promise.reject(error);
    // }
  }
);

const apiRequest = async (
  method: EMethod,
  apiUrl: string,
  body?: IKey,
  headers?: IKey
) => {
  try {
    const requestHeaders = !headers ? {} : headers;
    if (keycloak.token)
      requestHeaders['Authorization'] = `Bearer ${keycloak.token}`;
    requestHeaders['Content-Type'] = 'application/json';
    if (method === EMethod.GET || method === EMethod.DELETE)
      return axios[method](url + apiUrl, { headers: requestHeaders });
    else if (
      method === EMethod.POST ||
      method === EMethod.PUT ||
      method === EMethod.PATCH
    )
      return axios[method](url + apiUrl, body, { headers: requestHeaders });
  } catch (err) {
    await _handleError(err);
  }
};

const outsideRequest = async (
  method: EMethod,
  url: string,
  body: IKey,
  headers: IKey
) => {
  try {
    const requestHeaders = !headers ? {} : headers;
    if (method === EMethod.GET || method === EMethod.DELETE)
      return axios[method](url, { headers: requestHeaders });
    else if (method === EMethod.POST || method === EMethod.PUT)
      return axios[method](url, body, { headers });
  } catch (err) {
    await _handleError(err);
  }
};

const _handleError = async (err: any) => {
  const status = err?.response?.status;
  toast.error(`Request error ${status ? status : ''}`);

  if (err && err.response) {
    if (err.response.status === 403) {
      localStorageService.destroy('user');
      window.location.replace('/');
    }
    let errorText;
    if (
      await (err.response.data &&
        err.response.data.error &&
        err.response.data.error.message)
    ) {
      errorText = await err.response.data.error.message;
    } else {
      errorText = await err.response.statusText;
    }
    console.log('Error: ', errorText);
  }

  return await err;
};

export default {
  apiRequest,
  outsideRequest,
};
