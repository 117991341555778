import React from 'react';
import { useTranslation } from 'react-i18next';
import { IColorPicker } from '../../../types';

import styles from './ColorPicker.module.scss';

const ColorPicker = ({ selected, color, label, onClick }: IColorPicker) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.wrapper} ${
        selected ? styles.active : styles.inactive
      }`}
      onClick={onClick}
    >
      <span className={styles.circle} style={{ background: color }} />
      <span className={styles.label}>{t(label)}</span>
    </div>
  );
};

export default ColorPicker;
