import React from 'react';
import ReactLoading from 'react-loading';
import useNotifications from './useNotifications';
import NotificationCard from '../../components/NotificationCard';
import { getId } from '../../utils';
import { IFilters } from '../../types';

import s from './Notifications.module.scss';

const Notifications = () => {
  const { loading, notifications, t } = useNotifications();

  return (
    <div className="container">
      {loading ? (
        <ReactLoading
          type={'spokes'}
          color={'#FFA217'}
          height={60}
          width={60}
          className="loader"
        />
      ) : (
        <div className={s['Container']}>
          <div className={s['Wrapper']}>
            <h2>{t('notifications.title')}</h2>
            {notifications && notifications.length ? (
              notifications.map((item: IFilters) => {
                return (
                  item._links?.self?.href !== undefined && (
                    <NotificationCard
                      data={item}
                      id={getId(item._links.self.href)}
                      key={getId(item._links.self.href)}
                    />
                  )
                );
              })
            ) : (
              <h1>{t('no.notifications')}</h1>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
