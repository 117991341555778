import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import Pagination from 'rc-pagination';
import Selector from '../../UI/Selector';
import { dataActions } from '../../../store/actions/data.actions';
import { makeQuery, PAGE_OPTIONS } from '../../../utils';
import { EDataStore, ERouterPath, IPagination, IState } from '../../../types';

import 'rc-pagination/assets/index.css';

const PaginationReact = ({ defaultPageSize, pathname, total }: IPagination) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataStore = useSelector((state: IState) => state.data);

  const handleDataActions = (query: string) => {
    dispatch({ type: EDataStore.SET_LOADING, loading: true });
    new Promise((resolve) =>
      resolve(
        dispatch(
          pathname === ERouterPath.Results
            ? (dataActions.searchByFilterQuery(
                `?${query}`
              ) as unknown as AnyAction)
            : (dataActions.getFavourites(`?${query}`) as unknown as AnyAction)
        )
      )
    ).then(() => {
      navigate({ pathname: pathname, search: `?${query}` });
      window.scrollTo(0, 0);
      dispatch({ type: EDataStore.SET_LOADING, loading: false });
    });
  };

  const onPageChange = async (current: number) => {
    const query = makeQuery({
      ...dataStore.selectedFilters,
      ...dataStore.pagination,
      page: current,
    });
    handleDataActions(query);
  };

  const onSizeChange = async (option: { value: number; label: string }) => {
    const query = makeQuery({
      ...dataStore.selectedFilters,
      ...dataStore.pagination,
      page: 1,
      size: option.value,
    });
    handleDataActions(query);
  };

  return (
    !dataStore.loading && (
      <div className="pagination-wrapper">
        <Pagination
          className="pagination-component"
          defaultPageSize={defaultPageSize}
          pageSize={dataStore.pagination?.size}
          defaultCurrent={dataStore.pagination?.page}
          current={dataStore.pagination?.page}
          onChange={onPageChange}
          showLessItems
          total={total}
        />
        <Selector
          additionalClass="selector-component"
          selectClassname="selectFilter_pagination"
          selectedOption={
            PAGE_OPTIONS.find((one) => one.value === Number(defaultPageSize)) ||
            PAGE_OPTIONS[0]
          }
          options={PAGE_OPTIONS}
          handleChangeSelect={(e: { value: number; label: string }) =>
            onSizeChange(e)
          }
        />
      </div>
    )
  );
};

export default PaginationReact;
