import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { dataActions } from '../../store/actions/data.actions';
import { FILTERS_REVERSE_MAP, translate } from '../../utils';
import { EReverse, ERouterPath, IState } from '../../types';

const useSaveNotification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataStore = useSelector((state: IState) => state.data);
  const [error, setError] = useState(false);
  const [notificationName, setNotificationName] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    error && setError(false);
    setNotificationName(e.target.value);
  };

  const handleSaveNotification = async () => {
    if (notificationName === undefined || notificationName?.length < 1)
      return setError(true);
    const data: { [key: string]: number | string } = {
      name: notificationName,
    };
    const store = dataStore.selectedFilters;
    Object.keys(store).map((key: string) => {
      if (key !== 'page' && key !== 'size') {
        data[key] = store[key].toString();
      }
    });
    new Promise((resolve) =>
      resolve(
        dispatch(dataActions.addNotification(data) as unknown as AnyAction)
      )
    ).then(() => {
      navigate({ pathname: ERouterPath.Notifications });
    });
  };

  const handleRemoveSearchItem = (label: EReverse) => {
    const filter = FILTERS_REVERSE_MAP[label];
    new Promise((resolve) =>
      resolve(
        dispatch(
          dataActions.deleteCertainFilter(filter) as unknown as AnyAction
        )
      )
    ).then();
  };

  const goBack = () => {
    navigate({ pathname: ERouterPath.Home, search: '/' });
  };

  return {
    data: dataStore.selectedFilters,
    error,
    goBack,
    handleInputChange,
    handleRemoveSearchItem,
    handleSaveNotification,
    loading: dataStore.loading === undefined ? true : dataStore.loading,
    t,
    translate: translate,
  };
};

export default useSaveNotification;
