import React from 'react';
import Select from 'react-select';
import { ISelector } from '../../../types';

import './Selector.module.scss';

const Selector = ({
  selectClassname,
  selectedOption,
  handleChangeSelect,
  options,
  placeholder,
  label,
  isDisabled,
  multiselect = false,
  additionalClass,
  isSearchable = false,
  isClearable = false,
}: ISelector) => {
  return (
    <div className={`selector ${additionalClass}`}>
      {label && <label className={'selectLabel'}>{label}</label>}
      <Select
        isMulti={multiselect}
        className={selectClassname}
        classNamePrefix={selectClassname}
        value={selectedOption}
        onChange={handleChangeSelect}
        options={options}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={isDisabled}
        // menuIsOpen={true}
      />
    </div>
  );
};

export default Selector;
