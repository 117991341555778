import React from 'react';
import useStep2 from './useStep2';
import Button from '../../UI/Button';
import Selector from '../../UI/Selector';
import InputRange from '../../UI/InputRange';
import { FUEL } from '../../../utils';
import { EFilter, ISelect } from '../../../types';

import s from '../Stepper.module.scss';

const Step2 = () => {
  const {
    filters,
    brands,
    handleSelectChange,
    getSelectedValue,
    models,
    resetFilter,
    t,
  } = useStep2();

  return (
    <>
      <div className={'Row'}>
        <div className={'Col Col-3'}>
          <Selector
            handleChangeSelect={(e: ISelect[]) =>
              handleSelectChange(e, EFilter.brand)
            }
            isClearable={true}
            isSearchable={true}
            label={t('label.brand')}
            multiselect={true}
            placeholder={t('label.brand')}
            selectClassname="selectFilter"
            options={brands}
            selectedOption={getSelectedValue(brands, EFilter.brand)}
          />
        </div>
        {filters?.brand && (
          <div className={'Col Col-3'}>
            <Selector
              handleChangeSelect={(e: ISelect[]) =>
                handleSelectChange(e, EFilter.model)
              }
              isClearable={true}
              isSearchable={true}
              label={t('label.model')}
              multiselect={true}
              options={models}
              placeholder={t('label.model')}
              selectClassname="selectFilter"
              selectedOption={getSelectedValue(models, EFilter.model)}
            />
          </div>
        )}
        <div className={'Col Col-3'}>
          <Selector
            handleChangeSelect={(e: ISelect[]) =>
              handleSelectChange(e, EFilter.fuel)
            }
            isClearable={true}
            isSearchable={true}
            label={t('label.fuel')}
            multiselect={true}
            options={FUEL.map((item) => {
              return { label: t(item.label), value: item.value };
            })}
            placeholder={t('label.fuel')}
            selectClassname="selectFilter"
            selectedOption={getSelectedValue(FUEL, EFilter.fuel)}
          />
        </div>
      </div>

      <div className={'Row'}>
        <div className={'Col Col-6'}>
          <InputRange label={`${t('label.price')}`} filter={EFilter.price} />{' '}
        </div>
        <div className={'Col Col-6'}>
          <InputRange
            label={`${t('label.mileage')}`}
            filter={EFilter.mileage}
          />
        </div>
      </div>

      <div className={'Row'}>
        <div className={'Col Col-6'}>
          <InputRange
            label={t('label.registration')}
            filter={EFilter.firstRegistrationYear}
          />
        </div>
      </div>

      <div className={'Row'}>
        <div className={'Col Col-6'}>
          <InputRange
            label={`${t('label.cubicCapacity')}`}
            filter={EFilter.cubicCapacity}
          />
        </div>
        <div className="Col Col-6">
          <InputRange
            label={t('label.horsePower')}
            filter={EFilter.horsePower}
          />
        </div>
      </div>

      <div className={s['Reset']}>
        <Button
          btnClass="btnPrimary"
          label={t('btn.reset')}
          onClick={() => resetFilter()}
        />
      </div>
    </>
  );
};

export default Step2;
