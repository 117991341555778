import React from 'react';
import useStep1 from './useStep1';
import Button from '../../UI/Button';
import { CAR_BODY_TYPE } from '../../../utils';

import styles from '../Stepper.module.scss';

const Step1 = () => {
  const { handleSelectCar, reset, selectedTypes, t } = useStep1();

  return (
    <div className={styles.Step1}>
      {CAR_BODY_TYPE.map((item) => {
        return (
          <div className={styles.Container} key={item.id}>
            <img
              alt={''}
              src={
                selectedTypes && selectedTypes.includes(item.id)
                  ? item.imgFilled
                  : item.img
              }
              onClick={() => handleSelectCar(item.id)}
            />
            <div className={styles.text}>
              <span>{t(item.name)}</span>
            </div>
          </div>
        );
      })}
      <div className={styles.Reset}>
        <Button
          btnClass="btnPrimary"
          label={t('btn.reset')}
          onClick={() => reset()}
        />
      </div>
    </div>
  );
};

export default Step1;
