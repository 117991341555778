import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../UI/Button';

import s from './Modal.module.scss';

const Modal = ({
  handleCancel,
  handleSubmit,
  text,
  title,
}: {
  handleCancel: (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  handleSubmit: (event: MouseEvent<HTMLButtonElement>) => void;
  text: string;
  title: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={s['Modal']}>
      <div className={s['Container']}>
        <h4>{t(title)}</h4>
        <p>{t(text)}</p>

        <div className={s['Button']}>
          <Button
            btnClass="btnGrey"
            label={t('btn.cancel')}
            onClick={handleCancel}
          />
          <Button
            btnClass="btnPrimary"
            label={t('btn.confirm')}
            onClick={handleSubmit}
          />
        </div>

        <div className={s['Close']} onClick={handleCancel}>
          <span>+</span>
        </div>
      </div>
    </div>
  );
};

export default Modal;
