import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';

const Carousel = ({ data }: { data: string }) => {
  const [images, setImages] = useState<
    { original: string; thumbnail: string }[]
  >([]);
  const [arrData, setArrData] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      const toArray = data.split(';');
      toArray.splice(-1, 1);
      setArrData(toArray);
    }
  }, [data]);

  useEffect(() => {
    if (arrData) {
      const list = mapImages(arrData);
      setImages(list);
    }
  }, [arrData]);

  const mapImages = (images: string[]) => {
    return images.map((el: string) => {
      return {
        original: el,
        thumbnail: el,
      };
    });
  };

  return (
    <div className="carousel-wrapper">
      <ImageGallery
        items={images}
        showPlayButton={false}
        showFullscreenButton={true}
        showNav={true}
        showBullets={false}
        showThumbnails={true}
        useBrowserFullscreen={false}
      />
    </div>
  );
};
export default Carousel;
