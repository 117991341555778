import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useTranslation } from 'react-i18next';
import { IState } from '../../../types';
import { dataActions } from '../../../store/actions/data.actions';
import { Dispatch } from 'react';

const useStep1 = () => {
  const { t } = useTranslation();
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const dataStore = useSelector((state: IState) => state.data);

  const handleSelectCar = async (id: string) => {
    let newTypes = dataStore.selectedFilters.bodyType
      ? [...dataStore.selectedFilters.bodyType]
      : [];
    if (newTypes.includes(id)) {
      newTypes = newTypes.filter((item) => item !== id);
    } else {
      newTypes.push(id);
    }
    if (newTypes.length > 0) {
      dispatch(
        dataActions.selectedFilters({
          bodyType: newTypes,
        }) as unknown as AnyAction
      );
    } else {
      reset();
    }
  };

  const reset = () => {
    const filters = dataStore.selectedFilters;
    delete filters.bodyType;
    dispatch(dataActions.selectedFilters(filters) as unknown as AnyAction);
  };

  return {
    handleSelectCar,
    reset,
    selectedTypes: dataStore.selectedFilters.bodyType,
    t,
  };
};

export default useStep1;
