import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../UI/Button';
import { BlogImage } from '../../assets/images';

import s from './BlogCard.module.scss';

const BlogCard = () => {
  const { t } = useTranslation();

  return (
    <div className={s['Wrapper']}>
      <div className={s['Media']}>
        <img alt={''} src={BlogImage} />
      </div>
      <div className={s['Container']}>
        <div>
          <div className={s['Title']}>
            <h2>Corvette Stingray</h2>
          </div>
          <p>{t('blog.card.title')}</p>
          <div className={s['Redirect']}>
            <Link
              to={{
                pathname: '/blog',
              }}
            >
              <Button btnClass="btnPrimary" label={t('btn.more')} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
