import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getId } from '../../utils';
import { IState, IVehicle } from '../../types';
import { dataActions } from '../../store/actions/data.actions';
import { AnyAction } from 'redux';

const useCard = (item: IVehicle) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataStore = useSelector((state: IState) => state.data);
  const [adId, setAdId] = useState('');
  const [singleImage, setSingleImage] = useState('');

  useEffect(() => {
    if (item && item._links && item._links.self && item._links.self.href) {
      getId(item._links.self.href, setAdId);
    }
  }, [item && item._links && item._links.self && item._links.self.href]);

  useEffect(() => {
    if (item.images) {
      const firstImage = getFirstWord(item.images);
      setSingleImage(firstImage);
    }
  }, [item]);

  const onCardClick = () => {
    dispatch(
      dataActions.setScroll({
        x: window.scrollX,
        y: window.scrollY,
      }) as unknown as AnyAction
    );
  };

  const getFirstWord = (str: string) => {
    const spaceIndex = str.indexOf(';');
    return spaceIndex === -1 ? str : str.substr(0, spaceIndex);
  };

  return { adId, onCardClick, singleImage, t, width: dataStore.width };
};

export default useCard;
