import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { dataActions } from '../../store/actions/data.actions';
import { EDataStore, IState } from '../../types';

const useFavourites = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataStore = useSelector((state: IState) => state.data);
  const { initialized } = useKeycloak();

  useEffect(() => {
    if (initialized) {
      fetchData();
    }
  }, []);

  const fetchData = () => {
    dispatch({ type: EDataStore.SET_LOADING, loading: true });
    Promise.all([
      dispatch(
        dataActions.getFavourites(
          location.search
            ? location.search
            : `?size=${dataStore.pagination?.size}&page=${dataStore.pagination?.page}`
        ) as unknown as AnyAction
      ),
      dispatch(dataActions.getUserFavorites() as unknown as AnyAction),
    ]).then(() => {
      dispatch({ type: EDataStore.SET_LOADING, loading: false });
    });
  };

  return {
    data: dataStore?.favourites,
    loading: dataStore.loading === undefined ? true : dataStore.loading,
    pagination: dataStore.pagination,
    t,
  };
};

export default useFavourites;
