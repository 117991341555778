import React, { Fragment } from 'react';
import useActiveFilter from './useActiveFilter';
import Button from '../UI/Button';
import { FILTERS_LABEL_MAP } from '../../utils';
import { EReverse, IReverseKeys } from '../../types';

import s from './ActiveFilter.module.scss';

const ActiveFilter = () => {
  const {
    activeFilters,
    editFilter,
    handleRemoveItem,
    t,
    translate,
    saveNotification,
  } = useActiveFilter();

  return (
    <div className={s['Container']}>
      {activeFilters !== undefined && (
        <>
          <div className={s['Filters']}>
            {activeFilters &&
              Object.keys(activeFilters).map((key: string) =>
                activeFilters[key as IReverseKeys]?.length ? (
                  <Fragment key={'filter_' + key}>
                    <div className={s['FilterItem']}>
                      <div>
                        <span>
                          {`${t(FILTERS_LABEL_MAP[key as EReverse])}:`}
                        </span>
                        {Array.isArray(activeFilters[key as IReverseKeys])
                          ? translate(
                              activeFilters[key as IReverseKeys] as string[],
                              key
                            )
                          : t(activeFilters[key as IReverseKeys] as string)}
                      </div>

                      <span
                        className={s['remove']}
                        onClick={handleRemoveItem}
                        data-label={key}
                      >
                        +
                      </span>
                    </div>
                  </Fragment>
                ) : (
                  ''
                )
              )}
          </div>
          <div className={s['Change']}>
            <Button
              btnClass="btnSecondary"
              label={t('btn.change.filters')}
              onClick={editFilter}
            />
            <Button
              btnClass="btnPrimary"
              label={t('stepper.setNotification')}
              onClick={saveNotification}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ActiveFilter;
