import moment from 'moment/moment';
import { EFilter, IReverse, ISelect } from '../types';
import {
  Compact,
  CompactFilled,
  Convertable,
  ConvertableFilled,
  Coupe,
  CoupeFilled,
  Minibus,
  MinibusFilled,
  MiniWan,
  MiniWanFilled,
  MotorHome,
  MotorHomeFilled,
  PickUp,
  PickUpFilled,
  Sedan,
  SedanFilled,
  Suv,
  SuvFilled,
  Wagon,
  WagonFilled,
} from '../assets/images/car-body';

export const CAR_BODY_TYPE = [
  {
    id: '7',
    name: 'sedan',
    selected: false,
    img: Sedan,
    imgFilled: SedanFilled,
  },
  {
    id: '6',
    name: 'wagon',
    selected: false,
    img: Wagon,
    imgFilled: WagonFilled,
  },
  {
    id: '1',
    name: 'suv',
    selected: false,
    img: Suv,
    imgFilled: SuvFilled,
  },
  {
    id: '4',
    name: 'coupe',
    selected: false,
    img: Coupe,
    imgFilled: CoupeFilled,
  },
  {
    id: '5',
    name: 'convertable',
    selected: false,
    img: Convertable,
    imgFilled: ConvertableFilled,
  },
  {
    id: '11',
    name: 'compact',
    selected: false,
    img: Compact,
    imgFilled: CompactFilled,
  },
  {
    id: '9',
    name: 'motorHome',
    selected: false,
    img: MotorHome,
    imgFilled: MotorHomeFilled,
  },
  {
    id: '10',
    name: 'pickup',
    selected: false,
    img: PickUp,
    imgFilled: PickUpFilled,
  },
  {
    id: '8',
    name: 'minibus',
    selected: false,
    img: Minibus,
    imgFilled: MinibusFilled,
  },
  {
    id: '3',
    name: 'minivan',
    selected: false,
    img: MiniWan,
    imgFilled: MiniWanFilled,
  },
];

export const CAR_BODY_TYPE_OBJ = {
  '7': 'sedan',
  '6': 'wagon',
  '1': 'suv',
  '4': 'coupe',
  '5': 'convertable',
  '11': 'compact',
  '9': 'motorHome',
  '10': 'pickup',
  '8': 'minibus',
  '3': 'minivan',
};

export const COLOR_OPTIONS = [
  {
    id: '10',
    title: 'color2',
    color: '#4A5054',
  },
  {
    id: '4',
    title: 'color3',
    color: '#F5F5DC',
  },
  {
    id: '14',
    title: 'color4',
    color: '#E7F972',
  },
  {
    id: '8',
    title: 'color5',
    color: '#FFA217',
  },
  {
    id: '16',
    title: 'color6',
    color: '#FF98EA',
  },
  {
    id: '15',
    title: 'color7',
    color: '#6A0F08',
  },
  {
    id: '6',
    title: 'color8',
    color: '#6A4CF7',
  },
  {
    id: '1',
    title: 'color9',
    color: '#000000',
  },
  {
    id: '17',
    title: 'color10',
    color: '#930BEE',
  },
  {
    id: '11',
    title: 'color11',
    color: '#FF2D00 ',
  },
  {
    id: '2',
    title: 'color12',
    color: '#777777',
  },
  {
    id: '18',
    title: 'color13',
    color: '#17FFC0',
  },
  {
    id: '12',
    title: 'color14',
    color: '#FFFFFF',
  },
  {
    id: '3',
    title: 'color15',
    color: '#533609',
  },
  {
    id: '7',
    title: 'color16',
    color: '#AFAFAE',
  },
  {
    id: '9',
    title: 'color17',
    color: '#FFF717',
  },
  {
    id: '5',
    title: 'color18',
    color: '#117D0A',
  },
  {
    id: '13',
    title: 'color19',
    color: '#cd7f32',
  },
  {
    id: '19',
    title: 'color20',
    color: '#add8e6',
  },
  {
    id: '20',
    title: 'color21',
    color: '#8A2BE2',
  },
  {
    id: '21',
    title: 'color22',
    color: '#800000',
  },
];

export const COLOR_TYPE_OBJ = {
  '1': 'color9',
  '2': 'color12',
  '3': 'color15',
  '4': 'color3',
  '5': 'color18',
  '6': 'color8',
  '7': 'color16',
  '8': 'color5',
  '9': 'color17',
  '10': 'color2',
  '11': 'color11',
  '12': 'color14',
  '13': 'color19',
  '14': 'color4',
  '15': 'color7',
  '16': 'color6',
  '17': 'color10',
  '18': 'color13',
  '19': 'color20',
  '20': 'color21',
  '21': 'color22',
};

export const DRIVE_TYPE_OBJ = {
  '1': 'drive3',
  '2': 'drive4',
  '3': 'drive2',
  '': 'drive1',
};

export const FILTERS_LABEL_MAP = {
  bodyType: 'label.bodyType',
  brand: 'label.brand',
  model: 'label.model',
  fuel: 'label.fuel',
  cubicCapacity: 'label.cubicCapacity',
  price: 'label.price',
  mileage: 'label.mileage',
  firstRegistrationYear: 'label.registration',
  seats: 'label.seats',
  driveType: 'label.driveType',
  gearbox: 'label.gearbox',
  country: 'label.country',
  horsePower: 'label.horsePower',
  bodyColor: 'label.color',
  entryDate: 'label.entryDate',
  name: 'label.name',
  location: 'label.location',
  distance: 'label.distance',
  radius: 'label.radius',
};

export const FILTERS_REVERSE_MAP: IReverse = {
  cubicCapacity: [`${EFilter.cubicCapacity}From`, `${EFilter.cubicCapacity}To`],
  entryDate: [`${EFilter.entryDate}From`, `${EFilter.entryDate}To`],
  firstRegistrationYear: [
    `${EFilter.firstRegistrationYear}From`,
    `${EFilter.firstRegistrationYear}To`,
  ],
  horsePower: [`${EFilter.horsePower}From`, `${EFilter.horsePower}To`],
  mileage: [`${EFilter.mileage}From`, `${EFilter.mileage}To`],
  price: [`${EFilter.price}From`, `${EFilter.price}To`],
  bodyColor: EFilter.bodyColor,
  bodyType: EFilter.bodyType,
  brand: [EFilter.brand, EFilter.model],
  country: EFilter.country,
  driveType: EFilter.driveType,
  fuel: EFilter.fuel,
  gearbox: EFilter.gearbox,
  model: EFilter.model,
  seats: EFilter.seats,
  location: [
    EFilter.location,
    EFilter.latitude,
    EFilter.longitude,
    EFilter.radius,
  ],
  distance: EFilter.radius,
};

export const FUEL: ISelect[] = [
  { label: 'electricDiesel', value: '1' },
  { label: 'electricPetrol', value: '2' },
  { label: 'electric', value: '3' },
  { label: 'diesel', value: '4' },
  { label: 'petrol', value: '5' },
  { label: 'gasPetrol', value: '6' },
  { label: 'bioethanol', value: '7' },
  { label: 'gas', value: '8' },
  { label: 'hybrid', value: '9' },
  { label: 'hydrogen', value: '10' },
  { label: 'ethanolPetrol', value: '11' },
];

export const FUEL_OBJ = {
  '1': 'electricDiesel',
  '2': 'electricPetrol',
  '3': 'electric',
  '4': 'diesel',
  '5': 'petrol',
  '6': 'gasPetrol',
  '7': 'bioethanol',
  '8': 'gas',
  '9': 'hybrid',
  '10': 'hydrogen',
  '11': 'ethanolPetrol',
};

export const GEARBOX_OBJ = {
  '1': 'gearbox2',
  '2': 'gearbox3',
  '': 'gearbox1',
};

export const PAGE_OPTIONS = [12, 30, 50, 80, 100, 200].map((value) => ({
  value: value,
  label: String(value),
}));

export const ENTRY_DATE_OBJ = {
  '1': {
    entryDateFrom: moment().subtract(100, 'years').format('YYYY-MM-DD'),
    entryDateTo: moment().format('YYYY-MM-DD'),
  },
  '2': {
    entryDateFrom: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    entryDateTo: moment().format('YYYY-MM-DD'),
  },
  '3': {
    entryDateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    entryDateTo: moment().format('YYYY-MM-DD'),
  },
  '4': {
    entryDateFrom: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    entryDateTo: moment().format('YYYY-MM-DD'),
  },
  '5': {
    entryDateFrom: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    entryDateTo: moment().format('YYYY-MM-DD'),
  },
};

export const ENTRY_DATE_STRING = {
  [moment().subtract(100, 'years').format('YYYY-MM-DD')]: '1',
  [moment().subtract(1, 'days').format('YYYY-MM-DD')]: '2',
  [moment().subtract(7, 'days').format('YYYY-MM-DD')]: '3',
  [moment().subtract(1, 'months').format('YYYY-MM-DD')]: '4',
  [moment().subtract(3, 'months').format('YYYY-MM-DD')]: '5',
};
