export const localStorageService = {
  set(type: string, data: string[]) {
    const newData = JSON.stringify(data);
    localStorage.setItem(type, newData);
  },
  destroy(type: string) {
    localStorage.removeItem(type);
  },
  get(type: string) {
    const data = localStorage.getItem(type);
    if (!data) return null;
    return JSON.parse(data);
  },
};
