import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useKeycloak } from '@react-keycloak/web';
import ReactLoading from 'react-loading';
import { authActions } from '../../store/actions/auth.actions';
import { isObjEmpty } from '../../utils';
import { IState } from '../../types';

import './Auth.module.scss';

const Login = () => {
  const { keycloak } = useKeycloak();
  const authStore = useSelector((state: IState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login().then();
    }
  }, []);

  useEffect(() => {
    if (keycloak.authenticated) {
      if (keycloak.authenticated && isObjEmpty(authStore))
        dispatch(authActions.login() as unknown as AnyAction);
    }
  }, [keycloak]);

  if (keycloak) {
    keycloak.authenticated ? (
      navigate('/')
    ) : (
      <div className="container">
        <ReactLoading
          type={'spokes'}
          color={'#FFA217'}
          height={60}
          width={60}
          className="loader"
        />
      </div>
    );
  } else {
    return (
      <div className="container">
        <ReactLoading
          type={'spokes'}
          color={'#FFA217'}
          height={60}
          width={60}
          className="loader"
        />
      </div>
    );
  }
};

export default Login;
