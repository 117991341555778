import { EAuthStore, TDispatchAuth } from '../../types';
import authService from '../../services/auth.service';
import { toast } from 'react-toastify';
import i18n from 'i18next';

const login = () => {
  const request = () => {
    return { type: EAuthStore.LOGIN_REQUEST };
  };
  const success = (user: any) => {
    return { type: EAuthStore.LOGIN_SUCCESS, user };
  };
  const failure = (error: Error) => {
    return { type: EAuthStore.LOGIN_FAILURE, error };
  };

  return (dispatch: TDispatchAuth) => {
    dispatch(request() as unknown as TDispatchAuth);
    return authService.getUser().then(
      (user) => {
        if (user) {
          dispatch(success(user) as unknown as TDispatchAuth);
          return user;
        }
      },
      (error) => {
        dispatch(failure(error) as unknown as TDispatchAuth);
      }
    );
  };
};

const update = (data: any) => {
  const request = () => {
    return { type: EAuthStore.UPDATE_REQUEST };
  };
  const success = (user: any) => {
    return { type: EAuthStore.UPDATE_SUCCESS, user };
  };
  const failure = (error: Error) => {
    return { type: EAuthStore.UPDATE_FAILURE, error };
  };

  return (dispatch: TDispatchAuth) => {
    dispatch(request() as unknown as TDispatchAuth);
    return authService.update(data).then(
      (user) => {
        if (user) {
          dispatch(success(user) as unknown as TDispatchAuth);
          toast.success(i18n.t('sucMsg.userUpdate'));
          return user;
        }
      },
      (error) => {
        dispatch(failure(error) as unknown as TDispatchAuth);
      }
    );
  };
};

const logout = () => {
  localStorage.clear();
  const request = () => {
    return { type: EAuthStore.LOGOUT_REQUEST };
  };
  const success = () => {
    return { type: EAuthStore.LOGOUT_SUCCESS };
  };
  const failure = (error: Error) => {
    return { type: EAuthStore.LOGOUT_FAILURE, error };
  };

  return (dispatch: TDispatchAuth) => {
    dispatch(request() as unknown as TDispatchAuth);
    return authService.logout().then(
      (result) => {
        dispatch(success() as unknown as TDispatchAuth);
        return result;
      },
      (error) => {
        dispatch(failure(error) as unknown as TDispatchAuth);
      }
    );
  };
};

export const authActions = {
  login,
  update,
  logout,
};
