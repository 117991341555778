import React from 'react';
import { useTranslation } from 'react-i18next';

import s from './TermsAndConditions.module.scss';

const TermsAndConditions = () => {
  const [t] = useTranslation();
  return (
    <div className="container">
      <div className={s['Wrapper']}>
        <h1>{t('terms.header')}</h1>
        <div className={s['Section']}>
          <p>{t('terms.1.1')}</p>
          <p>{t('terms.1.2')}</p>
          <p>{t('terms.1.3')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.2.1')}</p>
          <p>{t('terms.2.2')}</p>
          <p>{t('terms.2.3')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.3.1')}</p>
          <p>{t('terms.3.2')}</p>
          <p>{t('terms.3.3')}</p>
          <p>{t('terms.3.4')}</p>
          <p>{t('terms.3.5')}</p>
          <p>{t('terms.3.6')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.4.1')}</p>
          <p>{t('terms.4.2')}</p>
          <p>{t('terms.4.3')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.5.1')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.6.1')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.7.1')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.8.1')}</p>
          <p>{t('terms.8.2')}</p>
          <p>{t('terms.8.3')}</p>
          <p>{t('terms.8.4')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.9.1')}</p>
          <p>{t('terms.9.2')}</p>
          <p>{t('terms.9.3')}</p>
          <p>{t('terms.9.4')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.10.1')}</p>
          <p>{t('terms.10.2')}</p>
          <p>{t('terms.10.3')}</p>
          <p>{t('terms.10.4')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.11.1')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.12.1')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.13.1')}</p>
        </div>
        <div className={s['Section']}>
          <p>{t('terms.14.1')}</p>
          <p>{t('terms.14.2')}</p>
          <p>{t('terms.14.3')}</p>
        </div>
        <div className={s['Section']}>
          <p className={s['Bold']}>{t('terms.15.1')}</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
