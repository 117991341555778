import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Login } from '../pages/Auth';
import MainWrapper from './MainWrapper';
import PrivateRoute from './PrivateRoute';
import Home from '../pages/Landing/Landing';
import Blog from '../pages/Blog/Blog';
import Results from '../pages/Results/Results';
import SinglePost from '../pages/SinglePost';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions';
import Notifications from '../pages/Notifications/Notifications';
import Profile from '../pages/Profile/Profile';
import Favourites from '../pages/Favourites/Favourites';
import SaveNotification from '../pages/SaveNotification/SaveNotification';
import NoMatch from '../pages/NoMatch';
import { ERouterPath } from '../types';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={ERouterPath.Home} element={<MainWrapper />}>
        <Route index element={<Home />} />
        <Route path={ERouterPath.Blog} element={<Blog />} />
        <Route path={ERouterPath.Results} element={<Results />} />
        <Route path={ERouterPath.Login} element={<Login />} />
        <Route path={ERouterPath.SinglePost} element={<SinglePost />} />
        <Route path={ERouterPath.PrivacyPolicy} element={<PrivacyPolicy />} />
        <Route
          path={ERouterPath.TermsAndConditions}
          element={<TermsAndConditions />}
        />

        <Route element={<PrivateRoute />}>
          <Route path={ERouterPath.Notifications} element={<Notifications />} />
          <Route path={ERouterPath.Profile} element={<Profile />} />
          <Route path={ERouterPath.Favourites} element={<Favourites />} />
          <Route
            path={ERouterPath.SaveNotification}
            element={<SaveNotification />}
          />
        </Route>

        <Route path={ERouterPath.NoMatch} element={<NoMatch />} />
      </Route>
    </Routes>
  );
};

export { AppRoutes };
