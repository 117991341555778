import React from 'react';

const GoogleAd = () => {
  return (
    <div style={{ maxWidth: 500, maxHeight: 300 }}></div>
    // <Adsense
    //   client="ca-pub-4962766525721152"
    //   slot=""
    //   style={{ maxWidth: 500, maxHeight: 300 }}
    //   format=""
    // />
  );
};

export default GoogleAd;
