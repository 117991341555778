import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ToastContainer } from 'react-toastify';
import ReactLoading from 'react-loading';
import { AppRoutes } from './routes/AppRoutes';
import { IState } from './types';
import { dataActions } from './store/actions/data.actions';

const App = () => {
  const { initialized, keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const dataStore = useSelector((state: IState) => state.data);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleResize = () =>
      dispatch(dataActions.setWidth(window.innerWidth) as unknown as AnyAction);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (initialized || dataStore.width !== undefined) {
      if (keycloak.authenticated) {
        const language = keycloak.tokenParsed?.language?.toLowerCase();
        if (language) {
          i18n.changeLanguage(language).then(() => setIsLoading(false));
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    }
  }, [initialized, keycloak]);

  // keycloak.token === undefined

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      {isLoading ? (
        <div>
          <ReactLoading
            type={'spokes'}
            color={'#FFA217'}
            height={60}
            width={60}
            className="loader"
          />
        </div>
      ) : (
        <Suspense
          fallback={
            <ReactLoading
              type={'spokes'}
              color={'#FFA217'}
              height={60}
              width={60}
              className="loader"
            />
          }
        >
          <main className="app-wrapper">
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </main>
        </Suspense>
      )}
    </div>
  );
};

export default App;
