import React from 'react';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from 'moment';
import useCard from './useCard';
import Mark from '../Mark';
import Button from '../UI/Button';
import { formatPrice, numberWithCommas } from '../../utils';
import { url } from '../../environment';
import { ECardType, ICard } from '../../types';
import { NoResults, PhoneIcon, ShareIcon } from '../../assets/images';

import s from './Card.module.scss';

const Card = ({ item, cardType }: ICard) => {
  const { adId, onCardClick, singleImage, t, width } = useCard(item);

  return (
    <div className={s[cardType]}>
      {width === undefined ? (
        <ReactLoading
          type={'spokes'}
          color={'#FFA217'}
          height={60}
          width={60}
          className="loader"
        />
      ) : (
        <>
          <Link
            className={s['Media']}
            onClick={onCardClick}
            to={{
              pathname: `/ad/${adId}`,
            }}
          >
            <img alt={''} src={singleImage ? singleImage : NoResults} />
          </Link>

          <div className={s['Container']}>
            <div>
              <div className={s['Title']}>
                <h2>
                  <Link
                    onClick={onCardClick}
                    to={{
                      pathname: `/ad/${adId}`,
                    }}
                  >
                    {item.brand} {item.model}
                  </Link>
                </h2>
                <h1>{formatPrice(item.price)}</h1>
              </div>
              {item.firstRegistration ? (
                <h2 className={s['Registration']}>
                  {t('label.registration')}:{' '}
                  {moment(item.firstRegistration, 'YYYY-MM-DD').format(
                    'MM.YYYY'
                  )}{' '}
                  / {t('single.post.mileage')}: {numberWithCommas(item.mileage)}
                </h2>
              ) : (
                <h2 className={s['Registration']}>
                  {t('single.post.mileage')}: {numberWithCommas(item.mileage)}
                </h2>
              )}
              {item.description !== 'null' ? <p>{item.description}</p> : null}

              {/*Card Primary*/}
              {cardType === ECardType.cardPrimary && (
                <>
                  {width >= 768 && width < 1280 && (
                    <div className={s['OptionsIcons']}>
                      <Mark type="button" item={item} />
                      <Link
                        onClick={onCardClick}
                        to={{
                          pathname: `/ad/${adId}`,
                        }}
                      >
                        <Button btnClass="btnPrimary" label={t('btn.call')} />
                      </Link>
                      <a
                        href={`mailto:?body=${url}/#/ad/${adId}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Button btnClass="btnPrimary" label={t('btn.share')} />{' '}
                      </a>
                    </div>
                  )}

                  {width < 768 && (
                    <div className={s['OptionsIconsMobile']}>
                      <Mark type="icon" item={item} />
                      <Link
                        onClick={onCardClick}
                        to={{
                          pathname: `/ad/${adId}`,
                        }}
                      >
                        <img alt="" src={PhoneIcon} />
                      </Link>
                      <a
                        href={`mailto:?body=${url}/#/ad/${adId}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img alt={''} src={ShareIcon} />
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>

            {cardType === ECardType.cardPrimary && width >= 1280 && (
              <div className={s['Options']}>
                <Mark type="button" item={item} />
                <Link
                  onClick={onCardClick}
                  to={{
                    pathname: `/ad/${adId}`,
                  }}
                >
                  <Button btnClass="btnPrimary" label={t('btn.call')} />
                </Link>
                <a
                  href={`mailto:?body=${url}/#/ad/${adId}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button btnClass="btnPrimary" label={t('btn.share')} />{' '}
                </a>
              </div>
            )}

            {/*Card Secondary*/}
            {cardType === ECardType.cardSecondary && (
              <>
                {width >= 1280 ? (
                  <div className={s['OptionsScroll']}>
                    <Mark type="button" item={item} />
                    <a
                      href={`mailto:?body=${window.location.href}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Button btnClass="btnPrimary" label={t('btn.share')} />{' '}
                    </a>
                  </div>
                ) : (
                  <div className={s['Scroll']}>
                    {width >= 768 ? (
                      <div className={s['ScrollWeb']}>
                        <Mark type="button" item={item} />
                        <a
                          href={`mailto:?body=${window.location.href}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button
                            btnClass="btnPrimary"
                            label={t('btn.share')}
                          />{' '}
                        </a>
                      </div>
                    ) : (
                      <div className={s['ScrollMobile']}>
                        <Mark type="icon" item={item} />
                        <a
                          href={`mailto:?body=${window.location.href}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img alt={''} src={ShareIcon} />
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Card;
