import React, { ChangeEvent } from 'react';
import useProfile from './useProfile';
import Input from '../../components/UI/Input';

import s from './Profile.module.scss';

const ProfileFormItem = ({
  editMode,
  handleInputChange,
  isRequired,
  label,
  name,
  placeholder,
  type,
  value,
}: {
  editMode: boolean;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  label: string;
  name: string;
  placeholder: string;
  type: string;
  value: string;
}) => {
  const { t } = useProfile();

  return (
    <div className={s['Item']}>
      <p>
        {t(label)}
        {isRequired ? '*' : ''}:
      </p>
      <>
        {!editMode ? (
          <p>{value ? value : 'N/A'}</p>
        ) : (
          <div className={s['EditMode']}>
            <Input
              inputClass="InputPrimary"
              type={type}
              name={name}
              value={value === null ? '' : value}
              onChange={handleInputChange}
              placeholder={t(placeholder)}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default ProfileFormItem;
